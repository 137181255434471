@import '../../../../styles/colors';
@import '../../styles/layout';

.DeskTopbar{
    background-color: $darkPurple;
    display: flex;
    position: relative;
    min-height: $topbarHeight;
    height: $topbarHeight;
    vertical-align: middle;
    align-items: center;
    padding: 0px 15px;
    color: white;
    z-index: 31;

    img{
        height: 18px;
        max-width: 16px;
        filter: $filter_white;
        margin: 0px 5px ;
        &.s{
            height: 12px;
        }
        // border: 1px solid black;
    }
}

