.ReaderToolbar {
  position: absolute;
  display: flex;
  z-index: 5;
  gap: 30px;
  width: -moz-fit-content;
  width: fit-content;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 86px;
}

.toolbarMessage {
  z-index: 20;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  width: 200px;
  background-color: #665aef;
  border-radius: 3px;
  color: white;
  padding: 5px 10px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  right: 15px;
  top: 86px;
}
.toolbarMessage.positioning {
  width: 150px;
}
.toolbarMessage.error {
  background-color: #db5461;
}/*# sourceMappingURL=ReaderToolbar.css.map */