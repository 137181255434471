
@import '../../styles/layout';
@import '../../../../styles/shadows';

.Toolbar{
    background-color: $offWhite;
    width: fit-content;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    // border-radius: 3px;
    box-shadow: $lighterBoxShadow;

    top: calc($tabsBarHeight + $topbarHeight + 10px);

    &.popUp{
        left: 20%;
    }

    img{
        margin: 0px 9px;
        height: 18px;
    }
}