.Separator {
  height: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  cursor: col-resize;
  position: relative;
  color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-left: 1px solid #eff3ff;
  border-right: 1px solid #eff3ff;
}
.Separator .Separator-handle {
  background-color: #c7d2fe;
  width: 60%;
  height: 100px;
  border-radius: 900px;
  transition: 0.3s;
}
.Separator .separator-arrow-container {
  background-color: #665aef;
  height: 16px;
  padding: 2px;
  border-radius: 99px;
  scale: 0;
  transition: 0.2s;
  cursor: pointer;
  position: absolute;
  margin-left: -7px;
}
.Separator .separator-arrow-container.close-pdf {
  top: calc(50vh - 75px);
}
.Separator .separator-arrow-container.close-pdf .HoverTip {
  margin-left: 24px;
}
.Separator .separator-arrow-container.close-notebook {
  top: calc(50vh + 56px);
}
.Separator .separator-arrow-container.close-notebook .HoverTip {
  margin-left: -92px;
}
.Separator .HoverTip {
  display: none;
}
.Separator .HoverTip.separator {
  text-align: center;
  margin-top: -28px;
  width: 80px;
}
.Separator .separator-arrow {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
  height: 16px;
}
.Separator .separator-arrow.single {
  height: 22.4px;
}
.Separator:hover .Separator-handle {
  background-color: #665aef;
}
.Separator.hovered .separator-arrow-container {
  scale: 1;
}
.Separator.hovered .separator-arrow-container:hover .HoverTip {
  display: block;
}
.Separator .single-arrow-container {
  background-color: #665aef;
  display: flex;
  vertical-align: center;
  padding: 5px;
  border-radius: 999px;
  cursor: pointer;
}
.Separator .single-arrow-container .HoverTip {
  margin-top: 0px;
}
.Separator .single-arrow-container.open-source .HoverTip {
  left: 10px;
  margin-left: 20px;
}
.Separator .single-arrow-container.open-notes .HoverTip {
  right: 30px;
}
.Separator .single-arrow-container:hover .HoverTip {
  display: block;
}/*# sourceMappingURL=Separator.css.map */