
@import '../../../../../styles/colors';

.Navbar.laptop{
    
    height: 100vh;
    width: 240px;
    border-right: 2px solid #e0e7ff;
    position: relative;
    text-align: center;
    .Navbar-logo{
        height: 28px;
        margin-top: 26px;
        margin-bottom: 26px;
        // border: 1px solid green;
    }

    .navbar-explore-folder-container{

        padding: 10px 0px;

        height: calc(100vh - 369px);
        overflow-y: auto;
        overflow-x: hidden;

    }

}