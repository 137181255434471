.note-stub {
  position: absolute;
  z-index: 1;
  resize: none;
  mix-blend-mode: normal;
}

.linkpointer-gif {
  width: 64px;
  height: 64px;
}

.circle {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background-color: #665aef;
  border-radius: 999999px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.pulsating {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
}
.pulsating:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 9999px;
  background-color: #e0e7ff;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #665aef;
  border-radius: 999990px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}/*# sourceMappingURL=PdfLink.css.map */