@import '../../../../../../styles/colors';

.NotebookSelector{
    h3{
        font-size: 1.0em;
        margin: 5px 0px;
    }
    .subtitle{
        font-size: 0.9em;
        margin: 0px;
    }

    .NotebooksList-container{
        padding: 10px 0px;
        padding-top: 5px;
        margin-top: 20px;
        border-top: 1px solid $lightGrey;
        border-bottom: 1px solid $lightGrey;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        
        // justify-content: space-evenly;
    }

}

