

@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';


.ZoomBox{

    margin-left: 20px;
    height: 100%;
    user-select: none;
    text-align: center;
    // height: 100%;
}


.ZoomDropdown-input{

    border-radius: 3px;
    border: 1px solid $mediumGrey;
    width: 90%;
    margin-top: 6px;
    transition: 0.3s;

    &:focus{
        border: 1px solid $darkPurple
    }
}

.zoom-option{
    padding-left:12px;
    padding-right:12px;
    transition: 0.3s;
    border-radius: 3px;
    margin: 4px 5px;

    &:hover{
        background-color: $indigo200;
    }

    
    .zoom-option-label{
        float: left;
    }
    .zoom-option-shortcut{
        float:right;
        // color:rgb(197, 197, 197);
    }
}

