@import '../../../../../styles/colors';
@import '../../../styles/layout';


.NotebookMenu-container{
    height: calc(100vh - $topbarHeight - $tabsBarHeight);
    overflow: scroll;
}

.NotebookMenu{
    padding: 10px 20px;
}

.NotebooksMenu-header{
    padding-bottom: 5px;

    h2{
        font-size: 1.2em;
    }
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    border-bottom: 1px solid $mediumGrey;

    .NotebooksMenu-headerButtons{
        display: flex;
        align-items: center;
    }

    margin-bottom: 15px;
}