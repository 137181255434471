
.colorSelector-container{
    // margin-left: $toolbarWidth + 100px;
    // margin-top: 5px;
    // text-align: left;
    // margin-left: 10px;
    display:inline-block;
    // width: fit-content;
    z-index:1;
    transition:0.3s;
    text-align: left;
    min-width: 105px;
    // transform: translateX(-230px);
}

.btn-color{
    width: 30px;
    margin: -2px;
}

