.ReaderTabs-home-icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
  margin: 0px 10px;
  margin-right: 18px;
}

.writerTabsBar-ProfileButton {
  position: absolute;
  margin-left: auto;
  right: 10px;
}/*# sourceMappingURL=ReaderTabsBar.css.map */