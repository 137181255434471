.create-new-folder {
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 24px;
}
.create-new-folder .folder-description {
  display: flex;
  flex-direction: row;
}
.create-new-folder .input-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
}
.create-new-folder .input-field {
  width: 100%;
}
.create-new-folder .input-field.mobile {
  width: calc(100vw - 25px);
}
.create-new-folder .create-folder-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-direction: row-reverse;
  padding-top: 10px;
}
.create-new-folder .create-folder-buttons.mobile {
  width: calc(100vw - 20px);
}
.create-new-folder .motivation {
  font-size: 12px;
  padding-bottom: 15px;
}
.create-new-folder .motivation.mobile {
  width: 100vw;
  flex-wrap: wrap;
  display: flex;
}/*# sourceMappingURL=CreateFolder.css.map */