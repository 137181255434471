.PageTitle-pathSegment:hover {
  cursor: pointer;
  color: #665aef;
  text-decoration: underline;
}
.PageTitle-pathSegment.onDrop {
  color: black;
  background-color: #c7cafc;
  padding: 0px 10px;
  border-radius: 3px;
}/*# sourceMappingURL=Breadcrumbs.css.map */