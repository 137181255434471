/*Simple css to style it like a toggle switch*/

@import '../../../styles/variables';
@import '../../../styles/colors';



.theme-switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.6em;
  }

  .toggle-img{
    height:12px;
    width:12px;
    &.right{
        float:right;
        margin-left:5px;
        
    }
    &.left{
        float:left;
        margin-left:8px;
        filter: $filter_white;

    }
  }

  .theme-switch {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 50px;
  }
  
  .theme-switch input {
    display:none;
  }
  
  .slider {
    background-color: $lightPurple;
    bottom: 0;
    &.active-toggle{
        cursor: pointer;
    }
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  
  .slider:before {
    background-color: $clipboardColor;
    position: absolute;
    transition: .4s;
    content: "";

    height: 16px;
    width: 16px;

    // bottom: 4px;
    left: 5px;
    
  }
  .slider.active-toggle:before{
    //the circle (dark)
    background-color: $darkClipboardColor;
  }
  .slider.inactive-toggle:before{
    //the circle (light)
    background-color: $darkPurple;
  }

  .slider.static-toggle.dark:before{
    background-color: $darkClipboardColor;
  }

  .slider.static-toggle.light:before{
    background-color: $desklampGrey;
  }

.slider{
    //the track
    
    &.inactive-toggle.multi-toggle{
        background-color: $lightPurple;
    }
    &.active-toggle.multi-toggle{
        // background-color: $darkTextboxColor;
        background-color: #696b77;

    }
    &.static-toggle{
      &.dark{
        // background-color: $darkTextboxColor;
        background-color: #696b77;
      }
      &.light{
        background-color: $readerBgColor;
      }
    }
}
  
  .slider.active-toggle:before {
    transform: translateX(24px);
  }

  
  .slider.round {
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  