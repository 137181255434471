.PdfViewer {
  flex: auto;
  position: relative;
  width: 100%;
}

.PdfReader {
  position: absolute;
  width: 100%;
  height: calc(100vh - 44px - 32px);
  overflow: auto;
  text-align: left;
}

.PdfViewer .CustomHighlightLayer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  opacity: 1;
  mix-blend-mode: multiply;
  pointer-events: auto;
}

.PdfViewer .CustomAnnotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  opacity: 1;
  mix-blend-mode: normal;
}

.PdfViewer-loading-container {
  height: calc(100vh - 44px);
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}/*# sourceMappingURL=PdfViewer.css.map */