

.TopbarButtons{
    // border: 1px solid green;
    vertical-align: middle;
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    padding-right: 10px;

    &.laptop{

        .TopbarButtons-icon{
            height: 24px;
            margin: 0 auto;
            margin-right: 24px;
        }
        

    }
    
    &.mobile{

        .TopbarButtons-icon{
            height: 16px;
            margin: 0 auto;
            margin-right: 16px;
        }

    }
}

