.index-item .element {
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.index-item .element .open-element-icon {
  padding-right: 5px;
  width: 15px;
  text-align: center;
}
.index-item .element .open-element-icon.arrow-icon {
  cursor: pointer;
}
.index-item .element .open-element-icon .r {
  transform: rotate(0deg);
  transition: 0.3s;
}
.index-item .element .open-element-icon .d {
  transform: rotate(90deg);
  transition: 0.3s;
}
.index-item .element .element-title {
  cursor: pointer;
  font-size: 0.9em;
}
.index-item .index-bar-element-seperator {
  border-bottom: 2px #e0e7ff solid;
  margin-left: 10px;
}
.index-item .index-items {
  padding-left: 10px;
}/*# sourceMappingURL=IndexElement.css.map */