
@import '../../../styles/shadows';
@import '../../../styles/variables';

.uploads-container{
    position: fixed;
    bottom: 40px;
    right: 50px;
    padding: 10px;
    border-radius: 2px;
    font-size: 14px;
    box-shadow: $boxShadow;
    margin-bottom: 10px;
    text-align: left;
    z-index: 9999;
    background-color: $lightPurple;

    &.dark{
        background-color: $darkDesklampGrey;
    }
    &.light{
        background-color: $clipboardColor;
    }

    .closePopup-icon{
        height: 12px;
        width: 12px;
        position: absolute;
        right: 14px;
        top: 14px;
        padding:4px;
        border-radius: 50%;
        
        &:hover{
            background-color: $mediumPurple;
        }

    }

}

.upload-header{
    margin-left: 5px;
    font-weight: bold;
    margin-bottom: 15px;

    // background-color: blue;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
}

.progress-fileName{
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 3px;
    width: 150px;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.progress-row{
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.progress-bar-container{
    width:100px;
    margin-left: 10px; 
    &.app{
        width: 100%;
        margin-left: 0px;
    }
    height:12px;
    background-color:white;
    margin-right: 10px;
    display:inline-block;
    // border-radius: 3px;
       

    .progress-bar-fill{
        height:100%;
        color:transparent;
       
        background-color: $lightDesklampGreen;
        font-size: 12px;    
        // border-radius: 3px;
        
    }

    

}

.percentage{
    margin-left: 5px;
    font-size: 0.9em;
}
.upload-done-icon{
    width: 12px;
    height: 12px;
    margin-left: 4px;
    filter: $filter_lightModeGreen
}
