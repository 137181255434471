
@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';


.NewButton.mobile{
    background-color: $darkPurple;
    width: 48px;
    height: 48px;
    border-radius: 99px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    z-index: 1;

    position: fixed;
    bottom: 100px;
    right: 30px;
    box-shadow: $boxShadow;

    .NewButton-icon{
        height: 18px;
        margin: 0 auto;
        filter: $filter_white
        
    }
}
