@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';

.newButtonOverlay-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 0px;

    .IconButton{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6px;

        .IconButton-icon-wrapper{
            border: 1px $darkGrey solid;
            border-radius: 50px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .IconButton-icon{
                height: 24px;
                width: 24px;
            }
        }
    }
}