
@import '../../../styles/colors';

.Menu{
    height: fit-content;
    width: 100%;
    text-align: left;
    color: black;

    &.s{

        .label{
            font-size: 0.9em;
        }

        .Menu-icon{
            height: 24px;
            width: 24px;
        }

        .header{
            font-size: 0.9em;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .dropdownElement{
            padding: 4px;
        }

    }

    &.n{

        .label{
            font-size: 1.0em;
            margin-left: 20px;

        }

        .Menu-icon{
            height: 24px;
            width: 24px;
        }

        .header{
            font-size: 1.1em;
            margin-bottom: 15px;
        }

        .dropdownElement{
            padding: 5px 15px;
        }

    }

    .triangleElement{
        // -webkit-text-stroke: 0px;
        color: white;
        font-size: 25px;
        display: flex;
        justify-content: end;
        padding-right: 10%;
        margin-bottom: -25px;
        z-index: 10;
    }
    
    .list{
        height: fit-content;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
        margin: 0px;
        padding: 10px 0px;
        user-select: none;
    }
    
    .dropdownElement{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: 0.2s;
        cursor: pointer;
        // border-radius: 5px;

        &:hover{
            background-color: $lightHoverPurple;
        }

        &.toggle{
            &:hover{
                background-color: transparent;
            }
        }

        &.warning{
            color: $lightModeRed;
            .Menu-icon{
                filter: $filter_lightModeRed;
            }
        }
        

    }
    
    .clickable{
        display: flex;
        flex-direction: row;
        align-items: center;

    }
    
    .label{
        font-weight: 400;
    }

    
    .toggleOptions{
        display: flex;
        flex-direction: row;
        padding: 2px;
        background-color: #D9D9D9;
    }
    
    .separator{
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        border: none;
        border-bottom: 2px solid $lightGrey ;

    }
    
    .header{
        color: $darkPurple;
        font-weight: 600;
        display: flex;
        align-items: flex-start;
    }

    .li{
        &.l{
            // margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    
    
    
    @media only screen and (max-width: 600px){
    
        .triangleElement{
            display: none;
        }
        .dropdown{
            width: 100vw;
        }
        .list{
            box-shadow: 0px 0px;
        }
    
        .label{
            font-size: 18px;
            font-weight: 400;
        }
    
        .list{
            padding-top: 0;
        }
    
        .toggleOptions{
            margin-right: 50px;
        }
    
        .Menu-icon{
            height: 24px;
            width: 24px;
        }
    } 
}

.MenuElement-ToggleSwitch{
    margin-right: 7px;
}