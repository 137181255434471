.Tooltip {
  position: absolute;
  background-color: #eff3ff;
  margin-top: 20px;
  margin-left: -40px;
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.Tooltip .triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #eff3ff;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -5px;
}
.Tooltip .tooltip-section {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}/*# sourceMappingURL=Tooltip.css.map */