.Navbar.mobile {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 10px 0px;
  border-top: 2px solid #e0e7ff;
  text-align: center;
}
.Navbar.mobile .Navbar-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.Navbar.mobile .NavbarMenuItem {
  display: inline;
}
.Navbar.mobile .NavbarMenuItem .NavbarMenuItem-icon {
  height: 28px;
}
.Navbar.mobile .NavbarMenuItem .NavbarMenuItem-label {
  font-size: 12px;
  font-weight: bold;
}
.Navbar.mobile .NavbarMenuItem.active .NavbarMenuItem-icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
}
.Navbar.mobile .NavbarMenuItem.active .NavbarMenuItem-label {
  color: #665aef;
}/*# sourceMappingURL=Navbar.mobile.css.map */