.Button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  display: inline-flex;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
}
.Button.laptop {
  height: 36px;
  font-size: 1em;
  padding-left: 20px;
  padding-right: 20px;
}
.Button.laptop.s {
  height: 30px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
}
.Button.mobile {
  height: 36px;
  font-size: 1em;
  padding-left: 10px;
  padding-right: 12px;
}
.Button.mobile.s {
  height: 30px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
}
.Button.round {
  border-radius: 3px;
}
.Button.primary.solid {
  background-color: #665aef;
  color: white;
  border: 2px solid #665aef;
}
.Button.primary.solid:hover {
  background-color: #4338ca;
}
.Button.primary.solid .icon {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}
.Button.primary.ghost {
  color: #665aef;
}
.Button.primary.ghost .icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
}
.Button.primary.ghost:hover {
  background-color: #665aef;
  color: white;
}
.Button.primary.ghost:hover .icon {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}
.Button.primary.disabled {
  border: 2px solid lightgray;
  background-color: lightgray;
  color: gray;
}
.Button.primary.disabled .icon {
  filter: grayscale(100%);
}
.Button.primary.disabled:hover {
  border: 2px solid lightgray;
  background-color: lightgray;
  color: gray;
}
.Button.primary.disabled:hover.icon {
  filter: grayscale(100%);
}
.Button.secondary {
  color: #665aef;
}
.Button.secondary.solid {
  background-color: #e0e7ff;
}
.Button.secondary.solid:hover {
  background-color: #c7d2fe;
}
.Button.secondary.solid .icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
}
.Button.secondary.ghost {
  border: 2px solid black;
  color: black;
}
.Button.warning {
  color: #db5461;
}
.Button.warning .icon {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.Button.ghost {
  background-color: white;
  border: 2px solid;
}
.Button .loading-image {
  display: none;
}
.Button.loading {
  cursor: auto;
}
.Button.loading .loading-image {
  display: block;
}
.Button .loading-image {
  height: 1.5em;
  margin-left: 10px;
  width: auto;
}
.Button .loading-image.hideTextOnLoading {
  margin-left: 0px;
}
.Button .loading-image.img-left, .Button .loading-image.img-right {
  height: 20px;
  width: auto;
  right: 20px;
  top: 1em;
}
.Button .icon {
  height: 50%;
  vertical-align: middle;
}
.Button .icon.left-icon {
  margin-right: 15px;
}
.Button .icon.right-icon {
  margin-left: 15px;
}
.Button .dropdown_icon {
  height: 12px;
}

.Button-dropdown {
  position: absolute;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 2px 1px #e7e7e7;
  margin-top: 180px;
}/*# sourceMappingURL=Button.css.map */