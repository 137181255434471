.colorSelector-container {
  display: inline-block;
  z-index: 1;
  transition: 0.3s;
  text-align: left;
  min-width: 105px;
}

.btn-color {
  width: 30px;
  margin: -2px;
}/*# sourceMappingURL=ColorSelector.css.map */