
@import '../../../../../../styles/shadows';
@import '../../../../../../styles/colors';

$documentHeight: 150px;
$documentWidth: 115px;
$scale: 0.8;

.SimpleThumbnail{

    
    position: relative;
    background-color: white;
    box-shadow: $lightGreyShadow;
    text-align: center;
    height: $documentHeight * 0.8;
    max-height: $documentHeight * 0.8;
    width: $documentWidth * 0.8;
    max-width: $documentWidth * 0.8;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 10px;

    &.n{
        width: 100px;
        max-width: none;
        height: 140px;
        max-height: none;
        margin-right: 18px;
    }

    &.mobile{
        width: 15vw;
        max-width: 15vw;
        height: 20vw;
        max-height: 20vw;
    }

    display: flex;
    flex-flow: column;
    justify-content: center;

    .contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
    }

    .format{
        font-size: 0.8em;
        color: $darkPurple;
        opacity: 0.7;

        &.mobile{
            font-size: 0.7em;
            height: 0;
        }
    }
    
    .title{
        // margin-top: -10px;
        font-size: 0.9em;
        color: $darkerGrey;
        overflow: hidden;
        text-overflow: ellipsis;

        &.mobile{
            font-size: 0.65em;
        }
    }
    .author{
        font-size: 0.8em;
        color: $mediumGrey;

        &.mobile{
            font-size: 0.7em;
        }
    }

    transition: 0.3s;
    

    &:hover{
        box-shadow: $boxShadow;
        scale: 1.05
    }

    .thumbnail-icon{
        height: 1.5em;
        position: absolute;
        top: 20px;
        // left: 40px

        &.mobile{
           height: 1.2em;
        }

    }

}