/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.contextmenu-container {
  font-size: 0.9em;
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 11;
  background-color: white;
}
.contextmenu-container.contextMenu-mobile-view {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.329);
  z-index: 100;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0;
}
.contextmenu-container .contextMenu-mobile {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}

.cm-icon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.cm-icon.hidden {
  visibility: hidden;
}

.color-buttons-cm {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
}

.btn_color_cm {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
}
.btn_color_cm.light:hover {
  border: 1px solid #34353f;
}
.btn_color_cm.dark:hover {
  border: 1px solid #f2f2f7;
}

.cm-text {
  font-size: 16px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cm-text.hoverable:hover {
  background-color: #eff3ff;
}
.cm-text.pad-left {
  padding-left: 30px;
}
.cm-text.disabled:hover {
  background-color: transparent;
}
.cm-text.danger .cm-icon {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.cm-text.danger .label {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.cm-text.mobile {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cm-colors .cm-colorSelector {
  text-align: center;
  margin-top: 5px;
}

.contextMenu-hr {
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.5;
}/*# sourceMappingURL=contextMenu.css.map */