.DeskTabsBar {
  z-index: 1;
  min-height: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.DeskTabsBar.Writer-TabsBar {
  z-index: 100;
}/*# sourceMappingURL=DeskTabsBar.css.map */