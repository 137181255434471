@import '../../../../../../styles/colors';

.ToolbarIcon{
    &.active{
        img{
            filter: $filter_darkPurple;
        }
    }

    &.non-functional{
        img{
            filter: opacity(0.3);
        }
    }
}