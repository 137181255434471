.FileExplorer {
  width: 400px;
  background-color: white;
}
.FileExplorer .back-button {
  height: 10px;
  margin-right: 5px;
  padding: 5px;
  border-radius: 99px;
}
.FileExplorer .back-button:hover {
  background-color: #eff3ff;
}
.FileExplorer .navigator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #4b4b4b;
}
.FileExplorer .files-list {
  height: 300px;
  margin-bottom: 20px;
  overflow-y: scroll;
}
.FileExplorer .create-folder {
  display: flex;
  align-items: center;
}
.FileExplorer .new-folder-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
}
.FileExplorer .tick-icon {
  width: 14px;
  margin: 0px 10px;
}
.FileExplorer .tick-icon:hover {
  filter: invert(44%) sepia(70%) saturate(481%) hue-rotate(68deg) brightness(93%) contrast(85%);
}
.FileExplorer .close-icon {
  height: 10px;
  margin: 0px 10px;
}
.FileExplorer .close-icon:hover {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.FileExplorer .loading-create {
  height: 20px;
}
.FileExplorer .folder-name-input {
  width: 100%;
  margin-right: 10px;
}
.FileExplorer .folders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.FileExplorer .move-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  transition: 0.3s;
  cursor: default;
}
.FileExplorer .move-file.disable {
  cursor: not-allowed;
  color: lightgray;
}
.FileExplorer .move-file.disable:hover {
  background-color: none;
}
.FileExplorer .move-file.disable:hover .move-right-arrow {
  visibility: initial;
}
.FileExplorer .move-file:hover {
  background-color: #eff3ff;
}
.FileExplorer .move-file:hover .move-right-arrow {
  visibility: visible;
}
.FileExplorer .move-file-name {
  display: flex;
  flex-direction: row;
  width: 320px;
  height: 24px;
}
.FileExplorer .file-icon {
  height: 24px;
  width: 24px;
  padding-right: 15px;
  padding-left: 10px;
}
.FileExplorer .file-name {
  font-size: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.FileExplorer .move-right-arrow {
  padding-right: 20px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.FileExplorer .selected {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}
.FileExplorer .readings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.FileExplorer .writings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.FileExplorer .buttons {
  padding-top: 3px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.FileExplorer .buttons .createNewFolder {
  color: #665aef;
  font-weight: bold;
  font-size: 0.9em;
  transition: 0.3s;
  cursor: pointer;
}
.FileExplorer .buttons .createNewFolder.disabled {
  color: #4b4b4b;
  cursor: default;
}/*# sourceMappingURL=FileExplorer.css.map */