
@import '../../../../../../styles/colors';
.customHighlight {
  position: absolute;
  mix-blend-mode: color;
  cursor: pointer;
}/*# sourceMappingURL=Highlight.css.map */

.custom-highlight-container {
  pointer-events: inherit;
  cursor: pointer;
  mix-blend-mode: normal;
}


@keyframes color-change {
  0%   { filter: brightness(100%); }
  50%  { filter: brightness(120%);}
  100% { filter: brightness(100%); }
}
