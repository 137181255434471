.newButtonOverlay-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0px;
}
.newButtonOverlay-container .IconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.newButtonOverlay-container .IconButton .IconButton-icon-wrapper {
  border: 1px #727272 solid;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newButtonOverlay-container .IconButton .IconButton-icon-wrapper .IconButton-icon {
  height: 24px;
  width: 24px;
}/*# sourceMappingURL=NewButtonOverlay.css.map */