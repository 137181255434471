@import '../../../../styles/shadows';

.ReaderViewer{
    display: none;
    height: 100%;

    &.active{
        display: flex;
    }
}

.EmptyReaderViewer{
    overflow: hidden;
}

.EmptyReaderViewer-FileExplorer{
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    box-shadow: $boxShadow;
    padding: 10px;
    margin-bottom: 50px;
}

.EmptyViewer{
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}


.EmptyReaderViewer-text{

    width: 130px;
    font-size: 1.2em;

}

.EmptyReaderViewer-message{
    background-color: white;
    width: fit-content;
}


.EmptyReaderViewer-message-container{
    // border: 1px solid red;
    height: 100%;
    // display: flex;
    margin-top: 30%;
    cursor: pointer;
    border-radius: 100%;;
    width: 200px;
    height: 200px;
    background-color: white;
    box-shadow: $boxShadow;
    justify-content: center;

    text-align: center; 
    align-items: center;
}