.RightIcons {
  float: right;
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.RightIcons .RightIcons-icon {
  margin: 0px 12px;
  cursor: pointer;
}
.RightIcons .RightIcons-icon.s {
  width: 14px;
}
.RightIcons .RightIcons-menu {
  position: absolute;
  top: 36px;
  right: 0px;
  width: 200px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 3px;
}
.RightIcons .RightIcons-menu img {
  filter: none;
}/*# sourceMappingURL=RightIcons.css.map */