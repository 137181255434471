@import '../../../styles/variables';


  #progressInput {
    margin: 20px auto;
    width: 21%;
  }
  
  .circle-background,
  .circle-progress {
    fill: none;
  }
  
  .circle-background {
    stroke: #ddd;
  }
  
  .circle-progress {
    // stroke: red;
    &.light{
        stroke: $menuBgColor
    }
    &.dark{
        stroke: $menuBgColor
    }

    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: $menuBgColor
  }