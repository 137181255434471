.MultiProfilePreview {
  display: flex;
}
.MultiProfilePreview .Profile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50px;
  height: 38px;
  width: 38px;
  font-weight: bold;
  color: white;
}
.MultiProfilePreview .Profile.s {
  height: 24px;
  width: 24px;
}
.MultiProfilePreview .Profile.xs {
  height: 32px;
  width: 32px;
}
.MultiProfilePreview .Profile.l {
  height: 40px;
  width: 40px;
}
.MultiProfilePreview .Profile.first {
  background-color: #db5461;
  z-index: -4;
}
.MultiProfilePreview .Profile.second {
  background-color: #3a9434;
  z-index: -3;
  left: -10%;
  position: relative;
}
.MultiProfilePreview .Profile.third {
  background-color: #FFD22A;
  z-index: -2;
  left: -15%;
  position: relative;
}
.MultiProfilePreview .Profile.plus {
  background-color: #665aef;
  z-index: 0;
  left: -20%;
  position: relative;
}
.MultiProfilePreview .Profile.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.195);
}/*# sourceMappingURL=MultiProfilePreview.css.map */