
@import '../../../../../styles/colors';

.Header.laptop{
    padding: 10px 0px;

    .grey-underline{
        border: none;
        border-bottom: 2px solid $lightGrey;

    }

    .Header-Topbar{
        z-index: 2;
    }

    .Header-PageTitle{
        z-index: 1;
    }
}