@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';
@import '../../../styles/layout';

$findBarImageSize:0.8em;
$darkgreyColor: #c2c3c5;
$hoverColor: #dddedf;

.ReaderFindbar{

    &.hide{
        display: none;
    }
    
    border-radius:2px;
    right: 20px;
    z-index: 20;
    background-color: $lightHoverPurple;
    min-width: 250px;
    height: 32px;
    display: flex;
    align-items: center;

    top: calc($tabsBarHeight + $topbarHeight + 10px);
    box-shadow: $lighterBoxShadow;
    &.frozenTopbar{
        transform:translateY(40px);
    }

    button{
        margin-left:3px;
        // margin-right:3px;
        padding-top:3px;
        padding-bottom:3px;
        border-radius:5px;
        border: none;
    }
    

    button.active-btn{
        background-color:$darkgreyColor ;
    }

    button.waste{
        display: none;
    }

    button.err-msg{

        color: #db5461;
        &.dark{
            color: #ffa69e
        }
        &.light{
            color: #db5461
        }
    }

    .searchInput{
        margin-left:8px;
        margin-top:8px;
        margin-bottom:8px;
        margin-right:10px;
        width:150px;
        text-align:left;
        height:17px;
        border-radius: 2px;
        padding: 3px;
        background-color: $mediumPurple;
        font-size: 0.85em;
    }

    img{
        height:$findBarImageSize;
        width:$findBarImageSize;
    }

    .findButtonImage{
        // cursor:pointer;
        // background-color: #dddedf;
        padding:3px;
        // border:0.5px solid $findBarBorderColor;
        position:relative;
        padding:4px;
        border-radius:50%;
        margin-right:5px;

    }
    
    .closeFind{
        margin-right:10px;
        padding:3px;
        border-radius:50%;
        cursor: pointer;
        &:hover{
            background-color: pink;
        }
    }

    .findNext{
        transform:rotate(90deg)
    }
    .findPrev{
        transform:rotate(-90deg);
    }

    
        .searchInput{
            background-color:#D8D5FA ;
        }
        button.non-button:hover{
            background-color: #f2f2f7;
        }
        .findButtonImage{
            &:hover{background-color: $lightHoverPurple;}
        }
        .find-message{
            white-space: nowrap;
            color:#34353f
        }
        .err-msg{
            color:#db5461
        }
    

    &.dark{
        background-color: #34353f;
        .searchInput{
            background-color:#444758 ;
            color:white;
        }
        button.non-button:hover{
            background-color: #34353f;
        }
        ::placeholder{
            color:rgba(255, 255, 255, 0.589)
        }
        .findButtonImage{
            &:hover{background-color: $darkHoverPurple;}
        }
        .find-message{
            color: #f2f2f7
        }
        .err-msg{
            color: #ffa69e
        }
    }

    

}