.PdfReaderTopbar-navButtons {
  margin-left: 10px;
  display: flex;
}

.PdfReaderTopbar-controls {
  display: flex;
  height: 100%;
  flex: auto;
  align-items: center;
}/*# sourceMappingURL=PdfReaderTopbar.css.map */