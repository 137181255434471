
@import '../../../../styles/colors';

$scrollerColor: $darkPurple;

//a1b5ff
//edf1ff

.Home.laptop{

    display: flex;

    .HomeContents-container{
        width: 100%;
        height: 99vh;
        padding: 0px 20px;
        // border: 1px solid green;
    }

    .Home-ActionButtons{
        display: flex;
        align-items: center;
        width: fit-content;
        // border: 1px solid pink;
        button{
            margin-right: 20px;
        }
    }

    .Home-Files{
        // border: 1px solid black;
        height: calc(100% - 300px);
        overflow-y: scroll;
        margin-top: 32px;
        // border-top: 2px solid $lightGrey;
        // border-bottom: 2px solid $lightGrey;

        // border: 1px solid red;


    }

    
}