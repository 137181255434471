.Collaborator {
  display: flex;
}
.Collaborator .Collaborator-ProfilePreview {
  width: 85%;
}
.Collaborator .Collaborator-dropdown {
  display: flex;
}
.Collaborator .select-permission {
  outline: none;
  background-color: none;
  border: none;
  display: inline;
  margin-right: 16px;
}/*# sourceMappingURL=Collaborator.css.map */