// Purples
$lightPurple: #e0e7ff;
$purple: #665aef;
$mediumPurple: #c7cafc;
// $darkPurple: #4f46e5;
$darkPurple: #665aef;
$scrollTrackColor:#edf1ff;


$lightHoverPurple: #eff3ff;
// $darkHoverPurple: #2e27b6;
$darkHoverPurple: #4f49ba;

$indigo200: #c7d2fe;
$indigo700: #4338ca;

//Greys
$darkestGrey: #303030 ;
$darkerGrey: #4b4b4b ;
$darkGrey: #727272;
$clipboardGrey: #f2f2f7;
$mediumGrey: #c7c7c7 ;
$lightGrey: #e7e7e7 ;
$offWhite: #f5f7fc;
$inputGrey: #D9D9D9;


//Reds
$darkModeRed:#ffa69e;
$lightModeRed:#db5461;

//Greens
$darkModeGreen:#59ffa0;
$lightModeGreen:#3a9434;

//Oranges
$lightModeOrange: #FFEBE0;

//White
$white: #ffffff;

//comments Background
$commentsBG: #ADDDFF;

//Filters
$filter_lightModeRed: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
$filter_white: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
$filter_lightModeGreen: invert(44%) sepia(70%) saturate(481%) hue-rotate(68deg) brightness(93%) contrast(85%);

$filter_mediumPurple: invert(81%) sepia(8%) saturate(2398%) hue-rotate(198deg) brightness(101%) contrast(98%);
$filter_darkPurple: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);