@import '../../styles/layout';
@import '../../../../styles/colors';


.Reader{
    // height: calc(100% - $topbarHeight - $tabsBarHeight )
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;

    .indexBarOpened{
        margin-left: 0px;
        transition: 0.3s;
    }
  
    .indexBarClosed{
        // transform: translateX(-100px);
        margin-left: -230px;
        transition: 0.3s;
    }
}