.NotebookMenu-container {
  height: calc(100vh - 44px - 32px);
  overflow: scroll;
}

.NotebookMenu {
  padding: 10px 20px;
}

.NotebooksMenu-header {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 15px;
}
.NotebooksMenu-header h2 {
  font-size: 1.2em;
}
.NotebooksMenu-header .NotebooksMenu-headerButtons {
  display: flex;
  align-items: center;
}/*# sourceMappingURL=NotebookMenu.css.map */