
@import '../../../../../../styles/colors';

.NewDocument{
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 125px * 0.8;
    // height: 174px * 0.8;
    height: 125px;
    border: 1px dashed $mediumGrey;
    background-color: none;
    margin-right: 10px;
    margin-top: 13px;
    margin-bottom: 5px;

    &.mobile{
      height: 20vw;
      width: 15vw;
      max-width: 15vw;
      max-height: 20vw;
    }


    .plusIcon{
        width: 28px;
    }

}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 16px;
    margin-left: 24px;
    box-sizing: border-box;
    border: 16px solid $darkPurple;
    border-color: $darkPurple transparent $darkPurple transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  