$FilesPadding: 20px;

.Files{
    width: 100%;
    // border: 1px solid green;
    text-align: left;
    text-align: left;
    // padding: 20px 0px;

    &.empty{
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: 100%;
        opacity: 0.8;
    }

    &.mobile{
        position: fixed;
        height: calc(100% - 80px - 80px - $FilesPadding);
        overflow-y: auto;
        padding-top: 20px
    }

    .Files-Header{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        // margin-top: 24px;
    }

    .Files-Header-icon{
        height: 24px;
        margin-right: 14px;
    }
}

.Files-Documents{
    display: flex;
    flex-wrap: wrap;
}

.empty-folder{
    height: 200px;
}