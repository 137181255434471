.SharpButton {
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 25px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  display: inline;
  border: none;
}
.SharpButton.primary {
  background-color: #665aef;
  color: white;
}
.SharpButton.secondary {
  background: #e0e7ff;
  color: black;
}/*# sourceMappingURL=SharpButton.css.map */