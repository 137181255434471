.IndexBar {
  position: absolute;
  z-index: 30;
  height: calc(100% - 44px);
  background-color: #eff3ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  width: 330px;
}
.IndexBar .heading {
  color: #727272;
  font-weight: 600;
  font-size: 0.9em;
  padding-top: 18px;
  padding-left: 10px;
  padding-bottom: 0px;
}
.IndexBar.mobile {
  width: 100vw;
  height: calc(100vh - 44px - 32px);
  z-index: 6;
}

.index-list {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}/*# sourceMappingURL=IndexBar.css.map */