.Toolbar {
  background-color: #f5f7fc;
  width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.195);
  top: 86px;
}
.Toolbar.popUp {
  left: 20%;
}
.Toolbar img {
  margin: 0px 9px;
  height: 18px;
}/*# sourceMappingURL=Toolbar.css.map */