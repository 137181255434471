
.PdfReaderTopbar-navButtons{
    margin-left: 10px;
    // border: 1px solid green;
    display: flex;
}

.PdfReaderTopbar-controls{
    display: flex;
    height: 100%;
    // justify-content: center;
    flex: auto;
    align-items: center;
    // border: 1px solid pink;
}