.Reader {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
}
.Reader .indexBarOpened {
  margin-left: 0px;
  transition: 0.3s;
}
.Reader .indexBarClosed {
  margin-left: -230px;
  transition: 0.3s;
}/*# sourceMappingURL=Reader.css.map */