/* The slider itself */
.tooltip-slider {
  -webkit-appearance: none; /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 10px;
  position: static;
}

/* Mouse-over effects */
.tooltip-slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.tooltip-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 10px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  border-radius: 50%;
  background: #665aef; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.tooltip-slider::-moz-range-thumb {
  width: 10px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  background: #665aef; /* Green background */
  cursor: pointer; /* Cursor on hover */
}/*# sourceMappingURL=SliderInput.css.map */