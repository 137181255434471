
@import '../../../../../styles/shadows';

.RightIcons{
    float: right;
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .RightIcons-icon{
        margin: 0px 12px;
        cursor: pointer;
        &.s{
            width: 14px;
        }
    }

    .RightIcons-menu{
        position: absolute;
        top: 36px;
        right: 0px;
        width: 200px;
        // border: 1px solid green;
        background-color: white;
        box-shadow: $boxShadow;
        border-radius: 3px;
        img{
            filter: none;
        }
    }
}