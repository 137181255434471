.NavbarSection {
  text-align: left;
  margin-bottom: 20px;
}

.NavbarSection-header {
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
}

.NavbarSection-contents {
  border-top: 2px solid #e0e7ff;
  border-bottom: 2px solid #e0e7ff;
}/*# sourceMappingURL=NavbarSection.css.map */