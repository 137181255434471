
@import '../../styles/colors';
@import '../../styles/shadows';

.MessagePopup{
    z-index: 9999;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: $boxShadow;
    
    background-color: $darkHoverPurple;

    &.error{
        background-color: $lightModeRed;
    }

    border-radius: 3px;
    color: white;
    padding: 10px 20px;

    display: none;

    &.show{
        display: flex;
    }
    
    align-items: center;
    justify-content: center;

    .icon{
        width: 20px;
        margin-left: 10px;
    }

    .close{
        width: 10px;
        margin-left: 20px;
        filter: $filter_white;
        cursor: pointer;
        cursor: pointer;

        &:hover{
            filter: $filter_white;
        }

    }

}