.TabsBar {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 0px;
  height: 30px;
  align-items: center;
  width: calc(100% - 44px - 30px);
}
.TabsBar .tabs-container {
  height: 30px;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}
.TabsBar .newTabIcon {
  margin-left: 10px;
  padding-right: 10px;
  height: 18px;
  cursor: pointer;
}/*# sourceMappingURL=TabsBar.css.map */