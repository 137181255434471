
@import '../../../../../styles/colors';

.Navbar.mobile{
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 10px 0px;
    // margin-top: 30px;
    border-top: 2px solid $lightPurple;
    text-align: center;

    .Navbar-container{
        width: 80%;
        display: flex;
        justify-content: space-between;

    }

    .NavbarMenuItem{
        display: inline;
        // width: 64px;
        

        .NavbarMenuItem-icon{
            height: 28px;
        }
    
        .NavbarMenuItem-label{
            font-size: 12px;
            font-weight: bold;
        }

        &.active{

            .NavbarMenuItem-icon{
                filter: $filter_darkPurple
            }
            .NavbarMenuItem-label{
                color: $darkPurple;
            }
        }
    }
    
}