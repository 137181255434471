.Files {
  width: 100%;
  text-align: left;
  text-align: left;
}
.Files.empty {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  opacity: 0.8;
}
.Files.mobile {
  position: fixed;
  height: calc(100% - 80px - 80px - 20px);
  overflow-y: auto;
  padding-top: 20px;
}
.Files .Files-Header {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px;
}
.Files .Files-Header-icon {
  height: 24px;
  margin-right: 14px;
}

.Files-Documents {
  display: flex;
  flex-wrap: wrap;
}

.empty-folder {
  height: 200px;
}/*# sourceMappingURL=Files.css.map */