.Header.mobile {
  display: flex;
  padding: 0px 10px;
  box-shadow: 0px 0px 2px 1px #e7e7e7;
}
.Header.mobile .Header-item {
  align-items: center;
  display: flex;
}
.Header.mobile .PageTitle-container {
  width: 70%;
}
.Header.mobile .TopbarButtons-container {
  width: 25%;
}
.Header.mobile .ProfileButton-container {
  width: 10%;
}/*# sourceMappingURL=Header.mobile.css.map */