.FullScreenOverlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  width: 100vw;
  position: fixed;
  height: 100%;
  background-color: white;
  animation-name: fullScreenOverlay;
  animation-duration: 0.3s;
}
.FullScreenOverlay .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #e7e7e7;
  padding-left: 20px;
}
.FullScreenOverlay .heading .heading-left-part {
  display: flex;
  align-items: center;
  width: 100%;
}
.FullScreenOverlay .heading .heading-left-part .headingTitle {
  width: calc(100% - 66px);
  font-weight: bold;
  color: #665aef;
  font-size: 18px;
}
.FullScreenOverlay .heading .heading-left-part .backButton {
  display: flex;
  align-items: center;
  padding: 10px;
}
.FullScreenOverlay .heading .heading-right-part {
  display: flex;
  align-items: center;
  width: 75%;
}
.FullScreenOverlay .heading .heading-right-part .right-end-button-text {
  color: #665aef;
}
.FullScreenOverlay .heading .heading-right-part .right-end-icon {
  display: flex;
  align-items: center;
  padding: 10px;
}
.FullScreenOverlay .fullScreenOverlay-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;
}

@keyframes fullScreenOverlay {
  0% {
    left: 100vw;
  }
  100% {
    left: 0;
  }
}/*# sourceMappingURL=FullScreenOverlay.css.map */