.PageTitle-folder {
  color: #665aef;
}

.PageTitle-text {
  display: inline;
}

.PageTitle-location {
  color: #727272;
}

.PageTitle.laptop {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.PageTitle.laptop .PageTitle-location {
  font-size: 12px;
}
.PageTitle.laptop .PageTitle-folder {
  font-size: 24px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 6px;
  display: inline-flex;
  align-items: center;
}
.PageTitle.laptop .PageTitle-folder:hover .header-more-button {
  scale: 1;
}
.PageTitle.laptop .PageTitle-folder .header-more-button {
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
  scale: 0;
  transition: 0.3s;
}
.PageTitle.laptop .PageTitle-description {
  font-size: 14px;
  color: black;
}
.PageTitle.laptop .PageTitle-description.null {
  color: grey;
}

.PageTitle.mobile {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.PageTitle.mobile .backButton {
  padding-right: 15px;
}
.PageTitle.mobile .PageTitle-location {
  font-size: 10px;
}
.PageTitle.mobile .PageTitle-folder {
  font-size: 18px;
  font-weight: bold;
}
.PageTitle.mobile .PageTitle-description {
  font-size: 14px;
  color: black;
  display: none;
}
.PageTitle.mobile .PageTitle-description.null {
  color: grey;
}

.PageTitle-icon {
  background-image: url("../../../../../../assets/icons/FolderMain.svg");
  background-size: 90px; /* <------ */
  background-repeat: no-repeat;
  background-position: center center; /* optional, center the image */
  height: 75px;
  width: 95px;
  margin-right: 32px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.PageTitle-icon .folder-more-button {
  padding-right: 2px;
  visibility: hidden;
  width: 90px;
}
.PageTitle-icon .folder-more-button.hovered {
  visibility: visible;
  display: flex;
  justify-content: end;
}/*# sourceMappingURL=PageTitle.css.map */