@import '../../../../../styles/colors';

.PageTitle-pathSegment{

    &:hover{
        cursor: pointer;
        color: $darkPurple;
        text-decoration: underline;
    }

    &.onDrop{
        color: black;
        background-color: $mediumPurple;
        padding: 0px 10px;
        border-radius: 3px;
    }
    
}