
@import '../../styles/layout';
@import '../../../../styles/colors';

.Notebook{
    height: calc(100% - $topbarHeight - $tabsBarHeight);
    overflow: hidden;
    background-color: $clipboardGrey;
}

.request-bar{
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;

    .editor-request{
        text-decoration: underline;
        margin-left: 5px;

        &.light{
            color: black;
        }
    
        &.dark{
            color: white;
        }

    }

    &.light{
        color: black;
        background-color: $lightPurple;
    }

    &.dark{
        color: white;
        background-color: $lightPurple;
    }
}