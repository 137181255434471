.DeskTopbar {
  background-color: #665aef;
  display: flex;
  position: relative;
  min-height: 44px;
  height: 44px;
  vertical-align: middle;
  align-items: center;
  padding: 0px 15px;
  color: white;
  z-index: 31;
}
.DeskTopbar img {
  height: 18px;
  max-width: 16px;
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
  margin: 0px 5px;
}
.DeskTopbar img.s {
  height: 12px;
}/*# sourceMappingURL=DeskTopbar.css.map */