/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.notes-toolbar.inline {
  zoom: 0.8;
  -moz-transform: scale(0.8);
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.notebook-toolbar-button {
  margin: 3px;
  width: 22px;
  height: 22px;
  position: relative;
  padding: 3px;
  border-radius: 4px;
  transition: 0s;
}
.notebook-toolbar-button.inactive-nb-button.light:hover {
  background-color: #D8D5FA;
}
.notebook-toolbar-button.inactive-nb-button.dark:hover {
  background-color: #4e4e58;
}
.notebook-toolbar-button.hidden {
  display: none;
}

.active-nb-button.light {
  background-color: rgba(59, 63, 63, 0.368627451);
}
.active-nb-button.dark {
  background-color: rgba(196, 196, 196, 0.4862745098);
}

.dropbtn {
  width: 20px;
  min-width: 92px;
  display: inline-block;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
  padding-right: 5px;
}
.dropbtn.light {
  background-color: #D8D5FA;
  color: #34353f;
}
.dropbtn.dark {
  background-color: #4e4e58;
  color: #f2f2f7;
}

.dropdown-content {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  display: none;
  position: absolute;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  border-radius: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: -moz-fit-content;
  width: fit-content;
}
.dropdown-content.mobile {
  bottom: 30px;
}
.dropdown-content.laptop {
  top: 30px;
}
.dropdown-content.light {
  background-color: #f2f2f7;
}
.dropdown-content.dark {
  background-color: #34353f;
}

.nb-main-toolbar {
  display: flex;
  overflow-x: auto;
  padding-bottom: 250px;
  margin-bottom: -250px;
  margin-top: 10px;
}

.nb-main-toolbar::-webkit-scrollbar {
  display: none;
}

.activeColorButton {
  width: 22px;
  cursor: pointer;
}

.circle-button {
  cursor: pointer;
}

.notebook-toolbar-button-container {
  display: flex;
  justify-content: center;
}

.basedropdown {
  animation-name: popup;
  animation-duration: 0.1s;
  top: 30px;
  padding: 0;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.dropdown-arrow-icon {
  height: 5px;
  width: 5px;
  float: right;
  cursor: pointer;
}

.dropdown-menu-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.dropdown-entry-text {
  font-size: 15px;
  /* width: calc(100% - 20px); */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: auto;
}
.dropdown-entry-text.light {
  color: #34353f;
}
.dropdown-entry-text.light:hover {
  background-color: #BAB6F5;
}
.dropdown-entry-text.dark {
  color: #f2f2f7;
}
.dropdown-entry-text.dark:hover {
  background-color: #4e4e58;
}

.color-dropdown-content {
  animation-name: popup;
  animation-duration: 0.1s;
}

.color-dropdown {
  position: relative;
  display: inline-block;
  padding: 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.base-button {
  transform: none;
}

.base-button-span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.notes-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  height: inherit;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: -moz-max-content;
  width: max-content;
  z-index: 6;
  margin: auto;
  height: 32px;
  background-color: #f5f7fc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.195);
}
.notes-toolbar.mobile {
  position: fixed;
  bottom: 0;
}/*# sourceMappingURL=NotebookToolbar.css.map */