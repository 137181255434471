/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.TitledInput {
  font-family: "Poppins";
  position: relative;
  z-index: 3;
}
.TitledInput .error-message {
  margin-top: 5px;
  font-size: 0.66em;
  color: #db5461;
  text-align: left;
}

.titledinput-box {
  position: relative;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  width: 100%;
  transition: 0.3s;
  text-align: left;
}
.titledinput-box.active {
  border: 1px solid #665aef;
  box-shadow: 0 0 2px #665aef;
}
.titledinput-box.sharp {
  border-radius: 3px;
}

.input-icon {
  height: 1em;
  width: 1em;
  vertical-align: middle;
  margin: 0 5px;
  margin-left: 15px;
}

.input-label {
  position: absolute;
  margin: 10px;
  padding: 0 5px;
  background-color: #f2f2f7;
  font-weight: 200;
  font-family: "Poppins-Medium";
  color: #303030;
  top: -20px;
  left: -7px;
  font-size: 0.75em;
}

.titledInput {
  font-size: 0.75em;
  padding: 0.85em 0;
  padding-left: 10px;
  font-family: "Poppins";
  width: 90%;
  border-radius: 10px;
  background: none;
}
.titledInput.l {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 0.8em;
}
.titledInput.xl {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 0.9em;
}
.titledInput.no-icons {
  width: 90%;
}
.titledInput.one-icon {
  width: calc(90% - 1em - 10px);
}
.titledInput.two-icons {
  width: calc(90% - 2em - 20px);
}
.titledInput:focus {
  outline: none;
}

.dropdown-icon {
  float: right;
  margin-top: 0.67em;
  align-self: center;
  margin-right: 15px;
}

.titledinput-options-container {
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  position: absolute;
  margin-top: 10px;
  width: -moz-fit-content;
  width: fit-content;
  height: 150px;
  overflow-y: scroll;
  border-radius: 5px;
  background-color: white;
  z-index: 100;
  text-align: left;
}

.titledinput-option {
  padding: 5px 10px;
  font-size: 0.75em;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.titledinput-option:hover {
  background-color: #f2f2f7;
}
.titledinput-option .icon {
  margin-right: 10px;
}
.titledinput-option .text {
  margin-right: 20px;
}

.titledinput-options-container {
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: lightgrey;
  /* Works on Chrome, Edge, and Safari */
}
.titledinput-options-container::-webkit-scrollbar {
  width: 12px;
}
.titledinput-options-container::-webkit-scrollbar-track {
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.titledinput-options-container::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 3px solid white;
}

.error-input {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}
.error-input .input-label {
  color: #db5461;
}
.error-input .input-main-container {
  border: 1px solid #db5461;
}
.error-input .input-main-container.active {
  box-shadow: 0 0 2px #db5461;
}
.error-input .input-icon {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.error-input input {
  color: #db5461;
}
.error-input input::-moz-placeholder {
  color: #db5461;
}
.error-input input::placeholder {
  color: #db5461;
}

.titledInput:-webkit-autofill,
.titledInput:-webkit-autofill:hover,
.titledInput:-webkit-autofill:focus,
.titledInput:-webkit-autofill:active {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 30px #f2f2f7 inset !important;
}/*# sourceMappingURL=TitledInput.css.map */