@import '../../../../../../../../styles/colors';

$searchInputHeight: 38px;
$searchResultsMargin: 18px;

.searchResults-container{
    border: 1px grey solid;
    border-radius: 5px;
    margin-top: 18px;
    width: 100%;
    height: calc(70vh - $searchInputHeight - $searchResultsMargin);

    &.mobile{
        border: 0px;
        margin-top: 0px;
        height: calc(100%)
    }

    .searchResults-topbar{
        height: 36px;
        background-color: $lightGrey;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px grey solid;
        display: flex;
        align-items: center;

        &.mobile{
            display: none;
        }

        .filled-topbar{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: 600;
            color: $darkerGrey;
            overflow: hidden;

            .type{
                padding-left: 12px;
            }
            .name{
                padding-left: 50px;
                // width: 95%;
            }
            .location{
                padding-left: 300px;
            }
        }
    }

    .searchResults{

        display: flex;
        justify-content: center;
        max-height: calc(100% - 40px);

        .searchResults-box{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .searchResults-content{
                overflow-y: auto;
                max-height: calc(80vh - 92px);
                width: 100%;
            }

            .no-more-results-text{
                font-weight: 300;
                font-style: italic;
                padding-top: 70px;
            }
        }

        .emptySearchResults{
            padding-top: 80px;
            font-weight: 300;
            font-style: italic;
        }

    }
}