.ReaderFindbar {
  border-radius: 2px;
  right: 20px;
  z-index: 20;
  background-color: #eff3ff;
  min-width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  top: 86px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.195);
}
.ReaderFindbar.hide {
  display: none;
}
.ReaderFindbar.frozenTopbar {
  transform: translateY(40px);
}
.ReaderFindbar button {
  margin-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  border: none;
}
.ReaderFindbar button.active-btn {
  background-color: #c2c3c5;
}
.ReaderFindbar button.waste {
  display: none;
}
.ReaderFindbar button.err-msg {
  color: #db5461;
}
.ReaderFindbar button.err-msg.dark {
  color: #ffa69e;
}
.ReaderFindbar button.err-msg.light {
  color: #db5461;
}
.ReaderFindbar .searchInput {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  width: 150px;
  text-align: left;
  height: 17px;
  border-radius: 2px;
  padding: 3px;
  background-color: #c7cafc;
  font-size: 0.85em;
}
.ReaderFindbar img {
  height: 0.8em;
  width: 0.8em;
}
.ReaderFindbar .findButtonImage {
  padding: 3px;
  position: relative;
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
}
.ReaderFindbar .closeFind {
  margin-right: 10px;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}
.ReaderFindbar .closeFind:hover {
  background-color: pink;
}
.ReaderFindbar .findNext {
  transform: rotate(90deg);
}
.ReaderFindbar .findPrev {
  transform: rotate(-90deg);
}
.ReaderFindbar .searchInput {
  background-color: #D8D5FA;
}
.ReaderFindbar button.non-button:hover {
  background-color: #f2f2f7;
}
.ReaderFindbar .findButtonImage:hover {
  background-color: #eff3ff;
}
.ReaderFindbar .find-message {
  white-space: nowrap;
  color: #34353f;
}
.ReaderFindbar .err-msg {
  color: #db5461;
}
.ReaderFindbar.dark {
  background-color: #34353f;
}
.ReaderFindbar.dark .searchInput {
  background-color: #444758;
  color: white;
}
.ReaderFindbar.dark button.non-button:hover {
  background-color: #34353f;
}
.ReaderFindbar.dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.589);
}
.ReaderFindbar.dark ::placeholder {
  color: rgba(255, 255, 255, 0.589);
}
.ReaderFindbar.dark .findButtonImage:hover {
  background-color: #4f49ba;
}
.ReaderFindbar.dark .find-message {
  color: #f2f2f7;
}
.ReaderFindbar.dark .err-msg {
  color: #ffa69e;
}/*# sourceMappingURL=ReaderFindbar.css.map */