.PageNavigator {
  color: white;
  display: flex;
  height: 100%;
  align-items: center;
}
.PageNavigator .bookpage {
  border-right: 1px solid #e0e7ff;
  margin-right: 5px;
  padding-right: 5px;
}
.PageNavigator .PageNavigator-inputs {
  margin-right: 5px;
}

.PageNavigator-input {
  background-color: #e0e7ff;
  width: 40px;
  color: #665aef;
}

.PageNavigatorDropdown {
  width: 200px;
  padding: 10px;
}
.PageNavigatorDropdown .PageNavigator-txt {
  width: 100%;
}/*# sourceMappingURL=PageNavigator.css.map */