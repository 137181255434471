.MessagePopup {
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  background-color: #4f49ba;
  border-radius: 3px;
  color: white;
  padding: 10px 20px;
  display: none;
  align-items: center;
  justify-content: center;
}
.MessagePopup.error {
  background-color: #db5461;
}
.MessagePopup.show {
  display: flex;
}
.MessagePopup .icon {
  width: 20px;
  margin-left: 10px;
}
.MessagePopup .close {
  width: 10px;
  margin-left: 20px;
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
  cursor: pointer;
  cursor: pointer;
}
.MessagePopup .close:hover {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}/*# sourceMappingURL=MessagePopup.css.map */