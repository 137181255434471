@import '../../../.././../../styles/colors';

.index-item{
    .element{
        display: flex;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        .open-element-icon{
            padding-right: 5px;
            width: 15px;
            text-align: center;

            &.arrow-icon{
                cursor: pointer;
            }

            .r{
                transform: rotate(0deg);
                transition: 0.3s;
            }

            .d{
                transform: rotate(90deg);
                transition: 0.3s;
            }
        }

        .element-title{
            cursor: pointer;
            font-size: 0.9em;
        }
    }

    .index-bar-element-seperator{
        border-bottom: 2px $lightPurple solid;
        margin-left: 10px;
    }

    .index-items{
        padding-left: 10px;
    }
}