
@import '../../../../styles/colors';

.SharingBox{
    text-align: left;
    margin-top: 24px;
    width: 100%;
    // border: 1px solid pink;

    .SharingBox-message{
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid $mediumPurple;
        // font-size: 0.9em;
        // margin-bottom: 20px;

        b{
            margin-right: 10px;
            color: $darkPurple;
        }
    }

    .Button{
        margin-right: 0px;
    }

    .People{
        width: 100%;
        // border: 1px solid green;

        .People-input-container{
            display: flex;
            height: 40px;
            // border: 1px solid green;
            justify-content: space-between;
            align-items: center;

            &.no-error{
                .input-element{
                    -webkit-box-shadow:inset 0px 0px 0px 1px $mediumGrey;
                    -moz-box-shadow:inset 0px 0px 0px 1px $mediumGrey;
                    box-shadow:inset 0px 0px 0px 1px $mediumGrey;

                }
            }


            .input-element{
                width: 84%;
                
                height: 100%;
                border-radius: 3px;
                display: flex;
                align-items: center;

                

                

                .input-collabs{

                    width: 100%;
                    &:focus{
                        border: none;
                        outline: none;

                    }
                }

                .select-permission{
                    outline: none;
                    background-color: none;
                    border: none;
                    display: inline;
                    font-size: 0.9em;
                    margin-right: 10px;
                }

                


            }

            &.error-input{
                .select-permission{
                    color: $lightModeRed;
                }
            }

            .people-icon{
                height: 20px;
                margin: 0px 10px;
            }

            .button-add{
                margin: 12px
            }
        }
        
    }

    .SharingBox-collaborators{
        margin-top: 20px;
        border: 1px solid $lightPurple;
        // border-bottom: 1px solid $lightPurple;
        // border-left: 1px solid $lightPurple;
        border-radius: 5px;

        padding: 5px;
        max-height: 200px;
        overflow-y: scroll ;

        

    }

    .SharingBox-footer{
        margin-top: 20px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .error-message{
        color: $lightModeRed;
        font-size: 0.9em;
        margin-top: 10px;
    }

    .loading-container-collaborators{
        // border: 1px solid red;
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .loading-collaborators{
        height: 3.0em;

    }

    .select-permission{
        background-color: white;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;

        background-image: url('../../../../assets/icons/down-arrowhead.svg');

        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 1.5em;
        
        background-size: 8px;
        

    }
    
 
    .select-arrowIcon{
        height: 10px;
        rotate: 90deg;
        margin-left: -5px;
        margin-right: 15px;

    }

}