
@import '../../../styles/colors';
@import '../../../styles/shadows';
@import '../../sizes';

$borderWeight: 2px;
$primaryColor: $darkPurple;
$secondaryColor: $lightPurple;

.Button{
    user-select: none;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.laptop{
        height: $normalHeight;
        font-size: 1.0em;
        padding-left: 20px;
        padding-right: 20px;

        &.s{
            height: $smallHeight;
            font-size: 0.9em;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &.mobile{
        height: $normalHeight;
        font-size: 1.0em;
        padding-left: 10px;
        padding-right: 12px;

        &.s{
            height: $smallHeight;
            font-size: 0.9em;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    font-weight: bold;

    display: inline-flex;
    width: fit-content;
    
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;

    &.round{
        border-radius: 3px;
    }

    &.primary{

        &.solid{
            background-color:$primaryColor;
            color: white;
            border: $borderWeight solid $primaryColor;

            &:hover{
                background-color:$indigo700;
            }

            .icon{
                filter:$filter_white
            }
        }

        &.ghost{

            color: $primaryColor;

            .icon{
                filter: $filter_darkPurple;
            }

            &:hover{
                background-color: $primaryColor;
                color: white;
                

                .icon{
                    filter: $filter_white;
                }
            }
        }

        &.disabled{
            border: 2px solid lightgray;
            background-color: lightgray;
            color: gray;
        
            .icon{
                filter:grayscale(100%)
            }

            &:hover{
                border: 2px solid lightgray;
                background-color: lightgray;
                color: gray;
            
                &.icon{
                    filter:grayscale(100%)
                }
            }
        }

    }
    &.secondary{
        color: $darkPurple;

        &.solid{
            background-color:$lightPurple;
            &:hover{
                background-color: $indigo200;
            }
            .icon{
                filter:$filter_darkPurple;
            }
        }

        &.ghost{
            border: $borderWeight solid black;
            color: black;
            
        }
        
    }
    &.warning{
        color:$lightModeRed;
        .icon{
            filter: $filter_lightModeRed
        }
    }

    &.ghost{
        background-color: white;
        border: $borderWeight solid;
    }

    //LOADING ICON

    .loading-image{
        display: none;
    }

    &.loading{
        cursor: auto;

        .loading-image{
            display: block;
        }
    }

    .loading-image{
        height: 1.5em;
        margin-left: 10px;

        &.hideTextOnLoading{
            margin-left: 0px;
        }
        width: auto;
        &.img-left, &.img-right{
            height: 20px;
            width: auto;
            right: 20px;
            top: 1em;
        }
    }
    

    .icon{
        height: 50%;
        &.left-icon{
            margin-right: 15px;
        }
        &.right-icon{
            margin-left: 15px;
        }
        vertical-align: middle;
    }

    .dropdown_icon{
        height: 12px;
    }
}

.Button-dropdown{
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: $lightGreyShadow;
    margin-top: 180px;
}