@import '../../../styles/colors';
@import '../../../styles/shadows';

.MultiProfilePreview{
    display: flex;

    .Profile{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 50px;
        height: 38px;
        width: 38px;
        font-weight: bold;
        color: white;

        &.s{
            height: 24px;
            width: 24px;
        }

        &.xs{
            height: 32px;
            width: 32px;
        }

        &.l{
            height: 40px;
            width: 40px;
        }

        &.first{
            background-color: $lightModeRed;
            z-index: -4;
        }
    
        &.second{
            background-color: $lightModeGreen;
            z-index: -3;
            left: -10%;
            position: relative;
        }

        &.third{
            background-color: #FFD22A;
            z-index: -2;
            left: -15%;
            position: relative;
        }

        &.plus{
            background-color: $purple;
            z-index: 0;
            left: -20%;
            position: relative;
        }

        &.shadow{
            box-shadow: $MultiProfileShadow;
        }
    }
}