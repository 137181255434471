@import '../../../../../styles/colors';

.DocumentTitle.writing {
    width: 100%;
}

.DocumentTitle{
    // width: 100%;
    margin: 0px 15px;
    text-align: left;
    // width: 30%;
    width: calc(50% - 130px);

    .DocumentTitle-input{
        font-size: 0.9em;
        vertical-align: center;
        justify-content: center;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        padding: 3px;
        border-radius: 2px;
        min-width: calc(100%);
        max-width: calc(100%);
        &:hover{
            border:1px solid $mediumPurple;
        }

    }
    .DocumentTitle-location{
        font-size: 0.60em;
        vertical-align: center;
        justify-content: center;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}