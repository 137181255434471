@import '../../styles/colors';
@import '../../styles//shadows';

.FullScreenOverlay{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    width: 100vw;
    position: fixed;
    height: 100%;
    background-color: white;
    animation-name: fullScreenOverlay;
    animation-duration: 0.3s;

    .heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom: 2px solid $lightGrey;
        padding-left: 20px;

        .heading-left-part{
            display: flex;
            align-items: center;
            width: 100%;

            .headingTitle{
                width: calc(100% - 66px);
                font-weight: bold;
                color: $darkPurple;
                font-size: 18px;
            }

            .backButton{
                display: flex;
                align-items: center;
                padding: 10px;
            }
        }

        .heading-right-part{
            display: flex;
            align-items: center;
            width: 75%;

            .right-end-button-text{
                color: $darkPurple;
            }

            .right-end-icon{
                display: flex;
                align-items: center;
                padding: 10px;
            }
        }
    }

    .fullScreenOverlay-content{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 10px 20px;
    }
}

@keyframes fullScreenOverlay {
    0% {left: 100vw;}
    100% {left: 0;}
}