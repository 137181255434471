.upload-files-container .link-input-button-container {
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-files-container .link-input-button-container.mobile {
  width: 100%;
  padding-top: 10px;
}
.upload-files-container .link-input-button-container .link-input-box {
  width: 100%;
  border: 1px grey solid;
  border-radius: 5px;
  height: 39px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 6px;
  margin-right: 10px;
}
.upload-files-container .link-input-button-container .link-input-box .link-input {
  width: 100%;
}
.upload-files-container .link-input-button-container .upload-button {
  margin-right: -10px;
}
.upload-files-container .browse-container {
  width: 100%;
  height: 400px;
  border: 1px grey solid;
  border-radius: 5px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-files-container .browse-container .browse-button {
  background-color: #665aef;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #665aef;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-files-container .browse-container .browse-button:hover {
  background-color: #4338ca;
}
.upload-files-container .browse-container .browse-button:hover .browse-button-text {
  background-color: #4338ca;
}
.upload-files-container .browse-container .browse-button .browse-button-text {
  color: white;
  font-weight: bold;
  font-size: 1em;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.upload-files-container .browse-container .browse-text {
  padding: 15px;
  width: 600px;
  text-align: center;
  margin-top: 15px;
  font-style: italic;
  color: grey;
}/*# sourceMappingURL=UploadPdf.css.map */