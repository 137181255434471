.create-new-folder{
    width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    .folder-description{
        display: flex;
        flex-direction: row;
    }

    .input-title{
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 12px;
    }

    .input-field{
        width: 100%;

        &.mobile{
            width: calc(100vw - 25px)
        }
    }

    .create-folder-buttons{
        // margin-left: 400px;
        display: flex;
        flex-direction: row;
        width: 100%;
        // float: right;
        flex-direction: row-reverse;
        &.mobile{
            width: calc(100vw - 20px)
        }
        padding-top: 10px;
    }

    .motivation{
        font-size: 12px;
        // padding-bottom: 30px;

        &.mobile{
            width: 100vw;
            flex-wrap: wrap;
            display: flex;
        }
        padding-bottom: 15px;
    }
}