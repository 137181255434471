@import '../../../../styles/colors';

$arrowHeight: 16px;

.Separator{
    // background-color: pink;
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    cursor: col-resize;
    position: relative;
    color: transparent;
    user-select: none;
    border-left: 1px solid $lightHoverPurple ;
    border-right: 1px solid $lightHoverPurple ;

    .Separator-handle{
        background-color: $indigo200 ;
        width: 60%;
        // margin-bottom: 50px;
        height: 100px;
        border-radius: 900px;
        transition: 0.3s;
    }

    
    .separator-arrow-container{
        background-color: $darkPurple;
        height: $arrowHeight;
        padding: 2px;
        border-radius: 99px;
        scale: 0;
        // scale: 1.0;
        transition: 0.2s;
        cursor: pointer;
        position: absolute;
        margin-left: -7px;

        &.close-pdf{
            top: calc(50vh - 75px);
            .HoverTip{
                margin-left: 24px;

            }
        }

        &.close-notebook{
           
            top: calc(50vh + 56px);

            .HoverTip{
                margin-left:-92px;
            }
        }

        


    }

    .HoverTip{
        display: none;
        
        &.separator{
            text-align: center;
            margin-top: -28px;
            width: 80px;
        }
    }

    .separator-arrow{
        filter: $filter_white;
        height: $arrowHeight;

        &.single{
            height: $arrowHeight*1.4;
            
        }
        
    }

    &:hover{
        .Separator-handle{
            background-color: $darkPurple;
        }
    }

    &.hovered{
        .separator-arrow-container{
            scale: 1.0;
            &:hover{
                .HoverTip{
                    display: block;
                }
            }

        }

        
    }

    .single-arrow-container{
        background-color: $darkPurple;
        display: flex;
        vertical-align: center;
        padding: 5px;
        border-radius: 999px;
        cursor: pointer;

        .HoverTip{
            margin-top: 0px;
            
        }

        &.open-source{
            .HoverTip{
                left: 10px;
                margin-left: 20px;
            }
        }

        &.open-notes{
            .HoverTip{
                right: 30px;
            }
        }

        &:hover{
            .HoverTip{
                display: block;
                
            }
        }
    }

    

}