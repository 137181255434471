@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';

$height: 100px;

.Folder{
    background-image: url('../../../../../assets/icons/FolderMain.svg');
    background-size:     contain;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;              /* optional, center the image */

    height: $height;
    width: 140px;
    margin-right: 18px;
    margin-bottom: 18px;
    position: relative;


    display: inline-flex;
    justify-content: space-between;
    // border: 1px solid black;
    align-items: center;
    vertical-align: center;
    user-select: none;
    cursor: pointer;

    &.mobile{
        width: 25vw;
        height: 80px;
    }

    .Folder-title{
        padding: 0px 12px;
        margin-top: 20px;
        // border: 1px solid green;
        height: 45px;
        font-size: 14px;
        font-family: 'Poppins-Medium';

        &.mobile{
            font-size: 0.8em;
            padding: 0px 7px
        }
    }

    .folder-more-button{
        padding-right: 2px;
        // visibility: hidden;
        scale: 0;
        position: absolute;
        right: 0px;
        transition: 0.3s;

        &.hovered{
            scale: 1.0
        }
    }

    &.onDropActive{
        border: 1px $darkPurple solid;
        border-radius: 15px;
        box-shadow: $boxShadow;
    }

}