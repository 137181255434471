
@import '../../styles/layout';

.DeskTabsBar{
    z-index: 1;
    min-height: $tabsBarHeight ;
    height: $tabsBarHeight;
    display: flex;
    align-items: center;
    padding-left: 10px;

    &.Writer-TabsBar{
        z-index: 100;
    }
}