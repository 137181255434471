@import '../../../../../../../styles/colors';

$height: 323px;

.explore-folders{


    .folder-component{

        display: flex;
        flex-direction: column;
        .folder{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2px 20px;
            padding-right: 0px;
            .folder-name{
                cursor: pointer;
                white-space: nowrap;
                overflow-wrap: unset;
                width: 80%;
            }
            .explore-folder-icon{
                padding: 0px 5px;
                cursor: pointer;
            }

            .explored{
                transform: rotate(90deg);
                transition: 0.3s;
            }
            
            .not-explored{
                transform: rotate(0deg);
                transition: 0.3s;
            }
        
            .folder-icon-name{
        
                display: flex;
                cursor: pointer;
        
                .folder-icon{
                    padding: 0px 5px;
                    display: flex;
                    align-items: center;
                }

                .opened{
                    font-weight: bold;
                }
            }
        }
    
        .isDropActive{
            border: 1px $darkPurple solid;
            background-color: $lightPurple;
        }

        .explored-folder-list{
            padding-left: 20px;
        }
    }

    .no-folders{
        padding-left: 24px;
        color: gray;
    }
}