/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.codeinput-container {
  margin: 0 0;
  display: flex;
  justify-content: space-between;
}

.ap-otp-input {
  border: 1px solid #c3c9cf;
  border-radius: 3px;
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 35px;
  font-family: "Poppins";
  font-size: 28px;
}

.ap-otp-input:focus {
  outline: none !important;
  border: 1px solid #665aef;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  transition: 0.12s ease-in;
}

.error .ap-otp-input {
  border: 1px solid #db5461;
}/*# sourceMappingURL=CodeInput.css.map */