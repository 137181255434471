@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';

.upload-files-container{

    .link-input-button-container{
        width: 600px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.mobile{
            width: 100%;
            padding-top: 10px;
        }

        .link-input-box{
            width: 100%;
            border: 1px grey solid;
            border-radius: 5px;
            height: 39px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 6px;
            margin-right: 10px;

            .link-input{
                width: 100%
            }
        }

        .upload-button{
            margin-right: -10px;
        }
    }

    .browse-container{
        width: 100%;
        height: 400px;
        border: 1px grey solid;
        border-radius: 5px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .browse-button{
            background-color: $darkPurple;
            width: fit-content;
            height: fit-content;
            border: 1px solid $darkPurple;
            border-radius: 3px;
            cursor: pointer;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
                background-color:$indigo700;

                .browse-button-text{
                    background-color: $indigo700
                }
            }

            .browse-button-text{
                color: white;
                font-weight: bold;
                font-size: 1.0em;
                padding-left: 18px;
                padding-right: 18px;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }

        .browse-text{
            padding: 15px;
            width: 600px;
            text-align: center;
            margin-top: 15px;
            font-style: italic;
            color: grey;
        }
    }
}