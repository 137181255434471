
@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';

.TopbarDropper{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 5px;
    font-size: 0.9em;
    user-select: none;
    -webkit-user-drag: none;
    white-space: nowrap;

    .Topbar-arrowIcon{
        transition: 0.4s;
        rotate: 90deg;
        height: 8px;
        margin-left:5px;
        margin-right: 0px;
        padding: 0px;

    }

    &:hover{

        background-color: darken($color: $darkPurple, $amount: 2)  ;


        .Topbar-arrowIcon{
            transform: translateX(3px);

        }
    }

    .topmenu-dropdown{
        position: fixed;
        padding: 5px 0px;
        border-radius: 5px;    
        background-color: $lightHoverPurple;
        margin-top: 30px;
        transform: translateX(-50%);
        color: black;
        box-shadow: $boxShadow;

    }
    
}