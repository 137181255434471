
@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';

$laptopHeight: 30px;
$mobileHeight: 24px;

.ProfileButton{
    user-select: none;
    
    border-radius: 999px;
    background-color: $darkPurple;
    font-weight: bold;
    color: white;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover{
        background-color: $purple;
    }

    &.laptop{
        font-size: 12px;
        height: $laptopHeight;
        width: $laptopHeight;
        line-height: $laptopHeight;

        &.s{
            height: $laptopHeight*0.8 ;
            width: $laptopHeight*0.8 ;
        }
    }

    &.mobile{
        height: $mobileHeight;
        width: $mobileHeight;
        line-height: $mobileHeight;
        font-size: 10px;
    }

}

.profileDropdown{
    box-shadow: $boxShadow;
    background-color: white;
    z-index: 200;
    position: absolute;
    right: 20px;
    top: 72px;
    padding: 10px 20px;

    background-color: white;
    border-radius: 2px;

    &.Writer-dropdown{
        top: 40px;
        right: 0px;
    }

    .ProfilePreview-container{
        padding:5px 15px;
    }

    hr{
        border:none;
        border-bottom: 2px solid $lightPurple;
    }
}