
@import '../../../styles/colors';

.ProfilePreview{
    display: flex;
    align-items: middle;
    
    .ProfilePreview-dp{
        background-color: $purple;
        margin: 10px 5px;
        color: white;
        font-weight: bold;
        padding: 6px;
        border-radius: 50%;
        font-size: 14px;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        user-select: none;
        text-align: center;

    }

    .ProfilePreview-account{
        display: flex;
        align-items: middle;
        vertical-align: middle;
        justify-content: center;
        text-align: left;

        .account-details{
            height: fit-content;
            margin: auto;
        }


        .name{
            font-size: 1.0em;
        }
        .email{
            font-size: 0.85em;
            color: $darkGrey
        }
        
    }
}