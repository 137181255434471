

$transitiontime: 0s;
$settingsTransparency: 0.3;


//APP DIMENSIONS
$topmenuHeight: 40px;
//change topbarHeight in Topbar.js
$toolbarHeight: 50%;
$toolbarWidth: 40px;
$toolbarRadius: 10px;
$leftGap: $toolbarWidth;
//change this.toolbarWidth in PDFLoader.js

//HOVER AND HIGHLIGHTS
$desklampGrey: #C4C4C4;
$darkDesklampGrey: #313941;
$lightDesklampGrey: #f2f2f7;
$lightHover: #BAB6F5;
$darkHover: #4e4e58;

$darkTopmenuColor: #323344;
$lightTopmenuColor: #F4F2F2;

/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/

$signupBarHeight: 24px;
$signupBarPadding: 5px;
$signupBarTotHeight: $signupBarHeight + $signupBarPadding + $signupBarPadding;


// ANNOTATIONS
$darkDesklampRed:#ffa69e;
$lightDesklampRed:#db5461;
$darkDesklampGreen:#59ffa0;
$lightDesklampGreen:#7ac74f;

// APP COLORS
$clipboardColor: #e6e5ee; //old: f0f2f5
$darkClipboardColor: #212231; //old: 202020
$readerBgColor: #f2f2f7;
$darkReaderBgColor: #34353f;
$menuBgColor: #665AEF;
$darkMenuBgColor: #3E33BF;
$activeTabBgColor: $lightTopmenuColor; //#564fbb;
// $darkActiveTabBgColor: #6C61E8;
$darkActiveTabBgColor: $darkTopmenuColor;
$ribbonColor: #BAB6F5;
// $inactiveTabBgColor: $darkClipboardColor;
$inactiveTabBgColor: #DDDBEC;
$darkInactiveTabBgColor: #272727;
$clipboardMessageColor: rgb(65, 64, 64);

//FONTS AND TEXT
$darkTextColor:$readerBgColor;
$textColor: $darkReaderBgColor;
$globalFont: "PT_Sans";
$headingSize:40px;
$subheadingSize:18px;
$contentSize:16px;
$containerHeight: 28px;

//BUTTTONS
$iconSize: 0.9 * $toolbarWidth;
$zoomIconSize: calc($iconSize/2);
$centerIconWidth: calc($iconSize/1.3);
$inputWidth: 30px;
$darkGrey: #c3c9cf;
$darkerGrey: #929597;
$darkActiveButtonColor: #c4c4c47c;
$activeButtonColor: #3b3f3f5e;

$darkDesklampButtonBg:#444758;
$desklampButtonBg:#665AEF;
// $desklampButtonBg: $lightHover;

//RADIO BUTTON DIMENSIONS
$menuRadioWidth:140px;
$menuRadioHeight:30px;
$menuRadioBGWidth:70px;
$menuRadioPadding: 10px;

//TEXTBOXES
// $textboxColor: #D0DDFF;
$textboxColor: #D8D5FA;
$darkTextboxColor:#444758;

//BOX SHADOW
$boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
$lightBoxShadow: 0px 0px 8px 6px rgba(109, 229, 238, 0.514);

//CUSTOM COLORS
$customDarkBg1: #363640;
$veryLightGrey: #c2c2c4;

//HOME SCREEN
$homeSidebarColor: $clipboardColor;
$darkHomeSidebarColor: $darkClipboardColor;

