@import '../../../styles/shadows';

.OverlayLaptop{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.329);
    z-index: 100;
    width: 100vw;
    height: 100vh;   


    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;

    &.mobile{
        
        // vertical-align: middle;
        align-items:flex-end;
        

        .Overlay-container{
            width: 100%;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }

    }

    .Overlay-container{
        box-shadow: $boxShadow;
        background-color: white;
        height: fit-content;
        // margin-top: 10%;
        border-radius: 5px;
        position: relative;
    }

    .Overlay-heading{
        border-bottom: 2px solid $lightPurple;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2em;
        margin-bottom: 10px;
        padding: 20px;


        &.overlay-heading-no-border{
            border-bottom: 0px;
        }

        .Overlay-heading-left-part{
            display: flex;
            align-items: center;
            width: 100%;
        }
        .Overlay-heading-icon{
            height: 24px;
            margin-right: 10px;
        }

        .Overlay-heading-title{
            width: calc(95% - 24px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
    }

    .Overlay-contents{
        padding: 0px 20px;
        padding-bottom: 20px;
    }

    .Overlay-close-icon{
        height: 14px;
        padding: 5px;
        border-radius: 999px;
        float: right;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $lightPurple;
        }

    }
}