
@import '../../../styles/variables';
@import '../../../styles/colors';


$iconSize: 1em;
$iconMargin: 5px;
$margin: 1em;

.TitledInput{
    font-family: 'Poppins';
    position: relative;
    z-index: 3;

    .error-message{
        margin-top: 5px;
        font-size: 0.66em;
        color: $lightModeRed;
        text-align: left;
    }
    

}



.titledinput-box{
    position: relative;
    border: 1px solid $mediumGrey;
    border-radius: 10px;
    width: 100%;
    transition: 0.3s;
    text-align: left;

    &.active{
        border: 1px solid $purple;
        box-shadow: 0 0 2px $purple;
    }

    &.sharp{
        border-radius: 3px;
    }
}

.input-icon{
    height: $iconSize;
    width: $iconSize;    
    vertical-align: middle;
    margin: 0 5px;
    margin-left: 15px;
}

.input-label{
    position: absolute;
    margin: 10px;
    padding: 0 5px;
    background-color: #f2f2f7;
    font-weight: 200;
    font-family: 'Poppins-Medium';
    color: $darkestGrey;

    // background-color: teal;
    // -webkit-transition: top .2s ease-in-out, font-size .2s ease-in-out;
    // transition: top .2s ease-in-out, font-size .2s ease-in-out;
    top: -20px;
    left: -7px;
    font-size: 0.75em;
}

// .active {
    
// }
.titledInput{

    font-size: 0.75em;
    padding: 0.85em 0;
    padding-left: 10px;
    font-family: 'Poppins';
    width: 90%;
    border-radius: 10px;
    background: none;

    &.l{
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 0.8em;
    }

    &.xl{
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 0.9em;
    }

    // border: 1px solid pink;

    &.no-icons{
        width: 90%;
    }

    &.one-icon{
        width: calc(90% - $iconSize - 2*$iconMargin);
    }

    &.two-icons{
        width: calc(90% - 2*$iconSize - 4*$iconMargin);
    }

    &:focus{
        outline: none;
    }

    
} 


.dropdown-icon{
    float: right;
    margin-top: 0.67em;
    // border: 1px solid green;
    align-self: center;
    margin-right: 15px;
    
}

.titledinput-options-container{
    padding: 10px ;
    box-shadow: $boxShadow;
    position: absolute;
    // top: 40px;
    margin-top: 10px;
    width: fit-content;
    height: 150px;
    overflow-y:scroll;
    border-radius: 5px;
    background-color: white;
    z-index: 100;
    text-align: left;
    
}

.titledinput-option{
    padding: 5px 10px;
    font-size: 0.75em;
    border-radius: 5px;
    user-select: none;
    &:hover{
        background-color: #f2f2f7;
    }

    .icon{
        margin-right: 10px;
    }
    .text{
        margin-right: 20px;
    }
}

.titledinput-options-container{

    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: lightgrey ;
  
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 12px;
    }
    
    &::-webkit-scrollbar-track {
        // background: orange;
        border-top-right-radius: 10px;
        border-top-right-radius: 10px;
        // border-left: 1px solid grey;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 20px;
        // margin-right: 5px;
        border: 3px solid white;
        // border-left: 1px solid grey;
    }
    
}


.error-input{

    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    .input-label{
        color: $lightModeRed;
    }

    .input-main-container{
        border: 1px solid $lightModeRed;
        &.active{
            box-shadow: 0 0 2px $lightModeRed;
        }
    }

    .input-icon{
        filter: $filter_lightModeRed;
    }

    input{
        &::placeholder{
            color: $lightModeRed
        }
        color:  $lightModeRed
    }
}

.titledInput:-webkit-autofill,
.titledInput:-webkit-autofill:hover, 
.titledInput:-webkit-autofill:focus, 
.titledInput:-webkit-autofill:active{
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 30px #f2f2f7 inset !important;
}