.searchResults-container {
  border: 1px grey solid;
  border-radius: 5px;
  margin-top: 18px;
  width: 100%;
  height: calc(70vh - 38px - 18px);
}
.searchResults-container.mobile {
  border: 0px;
  margin-top: 0px;
  height: 100%;
}
.searchResults-container .searchResults-topbar {
  height: 36px;
  background-color: #e7e7e7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px grey solid;
  display: flex;
  align-items: center;
}
.searchResults-container .searchResults-topbar.mobile {
  display: none;
}
.searchResults-container .searchResults-topbar .filled-topbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  color: #4b4b4b;
  overflow: hidden;
}
.searchResults-container .searchResults-topbar .filled-topbar .type {
  padding-left: 12px;
}
.searchResults-container .searchResults-topbar .filled-topbar .name {
  padding-left: 50px;
}
.searchResults-container .searchResults-topbar .filled-topbar .location {
  padding-left: 300px;
}
.searchResults-container .searchResults {
  display: flex;
  justify-content: center;
  max-height: calc(100% - 40px);
}
.searchResults-container .searchResults .searchResults-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.searchResults-container .searchResults .searchResults-box .searchResults-content {
  overflow-y: auto;
  max-height: calc(80vh - 92px);
  width: 100%;
}
.searchResults-container .searchResults .searchResults-box .no-more-results-text {
  font-weight: 300;
  font-style: italic;
  padding-top: 70px;
}
.searchResults-container .searchResults .emptySearchResults {
  padding-top: 80px;
  font-weight: 300;
  font-style: italic;
}/*# sourceMappingURL=SearchResults.css.map */