
.EditFileDetails{
    width: 500px;

    &.mobile{
        width: 100%
    }

    .folder-details-edit{
        margin: 32px 0px;
    }

    .buttons-row{
        // display: flex;
        text-align: right;

        // float: right;
        // justify-content: space-between;
    }
}