.Topbar.laptop {
  display: flex;
  vertical-align: middle;
  width: 100%;
  height: 64px;
  justify-content: space-between;
}
.Topbar.laptop .sidebuttons-container {
  display: flex;
}
.Topbar.laptop .Topbar-item {
  align-items: center;
}
.Topbar.laptop .SearchBar-container {
  width: 80%;
  display: flex;
}
.Topbar.laptop .ProfileButton-container {
  display: flex;
}
.Topbar.laptop .TopbarButtons-container {
  text-align: right;
  display: flex;
}/*# sourceMappingURL=Topbar.css.map */