@import '../../../../../styles/shadows';
@import '../../../../../styles/colors';
@import '../../../styles/layout';

.ReaderToolbar{
    position: absolute;
    display: flex;
    z-index: 5;
    gap: 30px;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 0%);
    top: calc($tabsBarHeight + $topbarHeight + 10px);

}

.toolbarMessage{
    z-index: 20;
    // position: absolute;
    text-align: center;
    // top: calc(90vh - $tabsBarHeight - $topbarHeight - 10px);
    box-shadow: $boxShadow;
    width: 200px;
    background-color: $darkPurple;
    border-radius: 3px;
    color: white;
    padding: 5px 10px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    // transform: translateX(-10%);
    position: absolute;
    right: 15px;
    top: 86px;


    &.positioning{
        width: 150px;
    }

    &.error{
        background-color: $lightModeRed;
    }
}