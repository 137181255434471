@import '../../../../../../../../../styles/colors';

$hoverIconsWidth: 74px;
$fileHeadingWidth: 360px;
$fileHeadingMarginLeft: 50px;

.searchResultElement-container{
    border-bottom: 1px grey solid;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &.mobile{
        width: calc(100vw);
    }

    &:hover{

        background-color: $lightHoverPurple;

        .hover-icons{
            display: flex;
            justify-content: space-around;
            align-items: center;

            .trash-icon, .star-icon{
                display: flex;
                justify-content: center;
                // width: 30px;
                padding: 5px 8px;

                .trash-icon-img{
                    filter: $filter_lightModeRed;
                }
            }
        }

        .fileLocation{
            width: calc(100% - $fileHeadingWidth - $fileHeadingMarginLeft - $hoverIconsWidth);

            &.mobile{display: none;}
        }
        
    }

    .file-info{
        display: flex;
        align-items: center;
        padding: 12px;
        width: 100%;
        overflow: hidden;

        .fileIcon{
            display: flex;
            align-items: center;
        }

        .fileHeading{
            margin-left: 50px;

            &.mobile{
                margin-left: 10px;
            }

            .fileDescription{
                font-size: 0.8em;
                display: flex;
                flex-wrap: wrap;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // display: -webkit-box;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            .fileName{
                color: $darkGrey;
                width: 360px;
                .bolden{
                    font-weight: bold;
                    color: $darkHoverPurple;
                }

                &.mobile{
                    width: 100%;
                }
            }
        }

        .fileLocation{
            width: calc(100% - $fileHeadingWidth - $fileHeadingMarginLeft);
            display: flex;
            flex-wrap: wrap;
            color: $darkGrey;
            margin-left: 30px;

            &.mobile{
                display: none;
            }
        }
    }

    .hover-icons{
        display: none;
    }
}