@import '../../../styles/colors';
$margin: 20px;



.secondary-button{

    .button-image{
        filter: invert(38%) sepia(46%) saturate(6099%) hue-rotate(233deg) brightness(98%) contrast(92%);
    }

    .button-text{
        color: $purple;
        position: relative;
    }

    .AuthButton{
        background-color: $lightPurple;
        border: 1px solid $mediumPurple;
        position: relative;

        &:hover{
            background-color: $indigo200;
        }

        &.loading{
            &:hover{
                background-color: $lightPurple;
            }
        }
    }

}


.primary-button{

    .button-image{
        filter: brightness(0) invert(1);        
    }

    .button-text{
        color: white
    }

    .AuthButton{
        background-color: $darkPurple;
        &:hover{
            background-color: $indigo700;
        }

        &.loading{
            &:hover{
                background-color: $darkPurple;
            }
        }
        
    }
}

.AuthButton{
    font-family: 'Poppins-Medium';
    transition: 0.3s;
    padding: 0.9em 0;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
    position: relative;

    &.fit{
        width: fit-content;
        padding: 10px 20px;
    }

    .loading-image{
        display: none;
    }

    &.loading{
        cursor: auto;

        .loading-image{
            display: block;
        }
    }

    .button-image{
        width: 1.333em;
        height: 1.333em;
        color: black;
        vertical-align: middle;
        // margin-right: 16px;
    
    }
    
    .loading-image{
        height: 1.77em;
        width: auto;
        position: absolute;
        right: 40px;
        top: 0.66em;
        &.img-left, &.img-right{
            height: 20px;
            width: auto;
            right: 20px;
            top: 1em;
        }
    }
    
    
    .button-text{
        // margin-left: 16px;
        font-family: 'Poppins-Bold';
        font-size: 1.0em;
    }
}

