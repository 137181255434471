@import '../../../../../styles/variables';
@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';

.selected-filters{
    margin-bottom: 0px;
    text-align: left;
    padding: 10px;
    padding-bottom: 0px;
}

.filterBox{
    padding: 5px;
    font-size: 0.9em;
    // display: block;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    // overflow: hidden;
    // float: left;
    border-radius: 3px;
    user-select: none;
    margin:5px;
    margin-bottom:10px;
    cursor: pointer;

    background-color: $mediumPurple;

}

.filterBox-color{
    display: inline-flex;
    width: 10px;
    height:10px;
    border-radius: 5px;
    margin-right: 5px;
}

.appliedFilter-item{
    display: flex;
    padding: 0px;
    align-items: center;
}

.annotation-icon-container{
    display: flex;
    align-items: center;
}

.annoType-filter-icon{
    height:1.0em;
    width: 1em;
    // padding:3px;
    border-radius: 3px;
    margin-right: 5px;

}