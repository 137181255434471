.OverlayMobile {
  background-color: rgba(0, 0, 0, 0.329);
  z-index: 100;
  width: 100vw;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.OverlayMobile .Overlay-container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  background-color: white;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  animation-name: overlay;
  animation-duration: 0.3s;
}
.OverlayMobile .Overlay-heading {
  border-bottom: 2px solid #e0e7ff;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  padding: 15px;
}
.OverlayMobile .Overlay-heading.overlay-heading-no-border {
  border-bottom: 0px;
}
.OverlayMobile .Overlay-heading .Overlay-heading-left-part {
  display: flex;
  align-items: center;
  width: 100%;
}
.OverlayMobile .Overlay-heading .Overlay-heading-icon {
  height: 24px;
  margin-right: 10px;
}
.OverlayMobile .Overlay-heading .Overlay-heading-title {
  width: calc(95% - 24px);
}
.OverlayMobile .Overlay-contents {
  padding: 0px 10px;
  padding-bottom: 10px;
}
.OverlayMobile .Overlay-close-icon {
  height: 14px;
  padding: 5px;
  border-radius: 999px;
  float: right;
  cursor: pointer;
}
.OverlayMobile .Overlay-close-icon:hover {
  background-color: #e0e7ff;
}

@keyframes overlay {
  0% {
    top: 200vh;
  }
  100% {
    top: 0px;
  }
}/*# sourceMappingURL=Overlay.mobile.css.map */