.LegacyDesklampPopup-container {
  width: 500px;
  padding: 10px;
  color: rgb(38, 38, 38);
}
.LegacyDesklampPopup-container a {
  text-decoration: none;
  color: #665aef;
}

.LegacyDesklamp-container {
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.LegacyDesklamp {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(255, 196, 82, 0.1254901961);
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #ffc452;
  border-radius: 5px;
  padding: 10px;
  transition: 0.3s;
  font-size: 0.8em;
  color: #4b4b4b;
}
.LegacyDesklamp:hover {
  background-color: rgba(255, 197, 82, 0.337254902);
}
.LegacyDesklamp .warning-icon {
  height: 1em;
  margin: 0px 10px;
}/*# sourceMappingURL=LegacyDesklamp.css.map */