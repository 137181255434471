
@import '../../styles/variables';
@import '../../styles/colors';

$size:16px;
$padding:10px;

$colorBtnHeight: 20px;
$colorBtnWidth: 20px;
$colorBtnGap: 5px;

.contextmenu-container{
    font-size: 0.9em;
    position:absolute;
    width:fit-content;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top:$padding;
    padding-bottom:$padding;
    user-select: none;
    z-index: 11;
    background-color: white;

    &.contextMenu-mobile-view{
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.329);
        z-index: 100;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0;
    }

    .contextMenu-mobile{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: fit-content;
        width: 100vw;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 10px 0px 10px 10px;
    }

}

.cm-icon{
    height:$size;
    width: $size;
    margin-right:5px;
    // margin: auto;

    &.hidden{
        visibility: hidden;
    }
}

.color-buttons-cm{
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
}

.btn_color_cm{
    height:$colorBtnHeight;
    width:$colorBtnWidth;
    margin-left: $padding;
    margin-right:$padding;

    border-radius: 50%;
    margin-top:5px;
    margin-right:$colorBtnGap; //100% - 4*(width %) / 8
    margin-left:$colorBtnGap;
    border:1px solid transparent;

    &.light{
        &:hover{
            border: 1px solid $darkReaderBgColor;
        }
    }
    &.dark{
        &:hover{
            border: 1px solid $readerBgColor
        }
    }
}

.cm-text{
    // margin-top:10px;
    font-size:$size;
    width:calc(100% - 20px); //2*$padding
    padding-left: $padding;
    padding-right:$padding;
    padding-top:6px;
    padding-bottom:6px;
    // margin-top: 5px;
    // margin-bottom: 5px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    gap: 8px;

    &.hoverable{
        &:hover{
        
            background-color: $lightHoverPurple;
        }
    }

    

    &.pad-left{
        padding-left: 30px
    }

    &.disabled{
        &:hover{
            background-color:transparent
        }
    }

    &.danger{
        .cm-icon{
            filter: $filter_lightModeRed;

        }
        .label{
            filter: $filter_lightModeRed;
        }
    }

    &.mobile{
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.cm-colors{

    
    .cm-colorSelector{
        // border: 1px solid red;
        text-align: center;
        margin-top: 5px;
    }
}




.contextMenu-hr{
    margin-top: 5px;
    margin-bottom:5px;
    opacity: 0.5;

}
