.SharingBox {
  text-align: left;
  margin-top: 24px;
  width: 100%;
}
.SharingBox .SharingBox-message {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #c7cafc;
}
.SharingBox .SharingBox-message b {
  margin-right: 10px;
  color: #665aef;
}
.SharingBox .Button {
  margin-right: 0px;
}
.SharingBox .People {
  width: 100%;
}
.SharingBox .People .People-input-container {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
}
.SharingBox .People .People-input-container.no-error .input-element {
  box-shadow: inset 0px 0px 0px 1px #c7c7c7;
}
.SharingBox .People .People-input-container .input-element {
  width: 84%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.SharingBox .People .People-input-container .input-element .input-collabs {
  width: 100%;
}
.SharingBox .People .People-input-container .input-element .input-collabs:focus {
  border: none;
  outline: none;
}
.SharingBox .People .People-input-container .input-element .select-permission {
  outline: none;
  background-color: none;
  border: none;
  display: inline;
  font-size: 0.9em;
  margin-right: 10px;
}
.SharingBox .People .People-input-container.error-input .select-permission {
  color: #db5461;
}
.SharingBox .People .People-input-container .people-icon {
  height: 20px;
  margin: 0px 10px;
}
.SharingBox .People .People-input-container .button-add {
  margin: 12px;
}
.SharingBox .SharingBox-collaborators {
  margin-top: 20px;
  border: 1px solid #e0e7ff;
  border-radius: 5px;
  padding: 5px;
  max-height: 200px;
  overflow-y: scroll;
}
.SharingBox .SharingBox-footer {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SharingBox .error-message {
  color: #db5461;
  font-size: 0.9em;
  margin-top: 10px;
}
.SharingBox .loading-container-collaborators {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.SharingBox .loading-collaborators {
  height: 3em;
}
.SharingBox .select-permission {
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: transparent;
  background-image: url("../../../../assets/icons/down-arrowhead.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 1.5em;
  background-size: 8px;
}
.SharingBox .select-arrowIcon {
  height: 10px;
  rotate: 90deg;
  margin-left: -5px;
  margin-right: 15px;
}/*# sourceMappingURL=SharingBox.css.map */