/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.annotationFilterSelector-main {
  font-size: 0.8em;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 330px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 2px;
}
.annotationFilterSelector-main.light {
  background-color: white;
}
.annotationFilterSelector-main.dark {
  background-color: #444758;
  color: white;
}
.annotationFilterSelector-main:after {
  content: "";
  display: table;
  clear: both;
}
.annotationFilterSelector-main .column {
  float: left;
}

.master-wrapper {
  width: 150px;
  margin-left: 10px;
}

.documentFilter-option {
  margin: 8px 0px;
  display: flex;
  align-items: center;
}
.documentFilter-option .radio {
  margin-top: 0px;
  margin-right: 10px;
}

.filter-topics {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 120px;
  text-align: left;
  height: 100%;
}

.filter-options {
  padding: 10px;
  padding-bottom: 20px;
  border-left: 1px solid #c7cafc;
}
.filter-options.light {
  border-left: 1px solid #BAB6F5;
}
.filter-options.dark {
  border-left: 1px solid #665AEF;
}

.filterTopic {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.filterTopic.activeTopic.light {
  background-color: #BAB6F5;
}
.filterTopic.activeTopic.dark {
  background: #665AEF;
}

.pagerange-input {
  margin-left: 10px;
  border-radius: 5px;
}
.pagerange-input.light {
  background-color: #D8D5FA;
  color: black;
}
.pagerange-input.dark {
  background-color: #444758;
  color: white;
}

.annotations-check-boxes {
  text-align: left;
}

.annotations-checkbox {
  margin-bottom: 5px;
}

.colorpicker-annotationsFilter {
  width: 100%;
  margin-left: 10px;
}/*# sourceMappingURL=AnnotationsFilterSelector.css.map */