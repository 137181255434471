@import '../../../../../styles/colors';

.WriterTabs-home-icon{
    filter: $filter_darkPurple;
    margin: 0px 10px;
}


.WriterTabs-home-icon{
    filter: $filter_darkPurple;
    margin: 0px 10px;
}

.WriterTabsBar{

    display: flex;

    text-align: center;
    width: 100%;
    font-size: 0.9em;

    .sliderTabs-container{
        
        display: flex;
        align-items: center;
        // height: fit-content;
    }

    .writerTabsBar-ProfileButton{
        position: absolute;
        margin-left: auto;
        display: flex;
        align-items: center;
        right: 10px;
    }

    justify-content: center;
    

    &.n{

        
        
        align-items: center;

        

        .sliderTabs-container{
            margin-left: 10px;

        }

        

    }
    &.s{
        padding: 0px 10px;
        .Workspace-text{
            display: none;
        }

        
        // justify-content: space-between;

    }

    &.xs{
        align-items: center;

        .Workspace-text, .sliderTabs-container{
            display: none;
        }
    }

    &.mobile{
        .Workspace-text, .sliderTabs-container{
            display: flex;
        }
    }
   
}