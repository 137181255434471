.EditFileDetails {
  width: 500px;
}
.EditFileDetails.mobile {
  width: 100%;
}
.EditFileDetails .folder-details-edit {
  margin: 32px 0px;
}
.EditFileDetails .buttons-row {
  text-align: right;
}/*# sourceMappingURL=EditFileDetails.css.map */