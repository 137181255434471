.Home.laptop {
  display: flex;
}
.Home.laptop .HomeContents-container {
  width: 100%;
  height: 99vh;
  padding: 0px 20px;
}
.Home.laptop .Home-ActionButtons {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.Home.laptop .Home-ActionButtons button {
  margin-right: 20px;
}
.Home.laptop .Home-Files {
  height: calc(100% - 300px);
  overflow-y: scroll;
  margin-top: 32px;
}/*# sourceMappingURL=Home.laptop.css.map */