.Header.laptop {
  padding: 10px 0px;
}
.Header.laptop .grey-underline {
  border: none;
  border-bottom: 2px solid #e7e7e7;
}
.Header.laptop .Header-Topbar {
  z-index: 2;
}
.Header.laptop .Header-PageTitle {
  z-index: 1;
}/*# sourceMappingURL=Header.laptop.css.map */