.Document {
  display: inline-block;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s;
  max-width: 125px;
  max-height: 174px;
  background-color: rgba(239, 243, 255, 0.5);
  text-align: center;
}
.Document.home {
  margin-right: 15px;
}
.Document.mobile {
  max-width: 20vw;
  max-height: 40vw;
}
.Document:hover {
  background-color: #eff3ff;
}
.Document.Notebook {
  background-color: rgba(239, 243, 255, 0.5);
}
.Document.Notebook:hover {
  background-color: #eff3ff;
}
.Document .Document-fileType {
  color: #4b4b4b;
  width: 100%;
  font-size: 10px;
  margin-left: 24px;
  font-weight: bold;
}
.Document .Document-Thumbnail {
  height: 108px;
  width: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  margin: 6px 0px;
}
.Document .Document-title {
  font-size: 12px;
  height: 40px;
  width: 100%;
  overflow: none;
  vertical-align: middle;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more-button {
  float: right;
  scale: 0;
  position: relative;
  transition: 0.3s;
  z-index: 2;
}
.more-button .more-icon {
  height: 16px;
  position: absolute;
  margin-left: -14px;
  margin-top: -3px;
}
.more-button.hovered {
  scale: 1;
}/*# sourceMappingURL=ThumbnailLamination.css.map */