.Notebook {
  height: calc(100% - 44px - 32px);
  overflow: hidden;
  background-color: #f2f2f7;
}

.request-bar {
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}
.request-bar .editor-request {
  text-decoration: underline;
  margin-left: 5px;
}
.request-bar .editor-request.light {
  color: black;
}
.request-bar .editor-request.dark {
  color: white;
}
.request-bar.light {
  color: black;
  background-color: #e0e7ff;
}
.request-bar.dark {
  color: white;
  background-color: #e0e7ff;
}/*# sourceMappingURL=NotebookWorkspace.css.map */