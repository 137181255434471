@import '../../../styles/colors';
@import '../../../styles/variables';

html{
    overflow-x: hidden;
}


.auth-logo{

    width: auto;
    position: absolute;
    top: 60px;

}

.dynamic-form{
    transition: 0.5s;
}

.auth-screen{

    // background-color: green;
    height: 100vh;
    text-align: left;
    display: flex;

    &.row:after{
        content: "";
        display: table;
        clear: both;
    }

    .column{
        // float: left;
        height: 100%;
        // width: 50%;
        background-color: #f2f2f7;
    }

}



.auth-form-column{
    
    display: flex;
    align-items: center;



    .authform-container{
        margin-bottom: 0px;
        // margin: 0 auto;
        
        min-width: 65%;
        z-index: 2;

        position: relative;
    }
}

.Auth-hr{
    margin: 30px 0px;
    border: none;
    border-bottom: 1px solid $mediumGrey;
}

.form{
    margin-top: 40px;
}

.list{
    list-style: none;
}

.list-container{
    list-style: none;
    height: 0;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.list-container.show:not(:first-child){
    margin-top: 10px;
}

.list-container .list-item{
    padding: 2rem 0;
    width: 100%;
    background-color: lightgray;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transition: all 0.6s ease-out;
}
.list-container .list-item.show{
    opacity: 1;
}

.info-panel{
    position: absolute;
    height: 100vh;
    width: 600px;
    right: 0px;
    top: 0px;
    background-color: $lightPurple;
    box-shadow: $boxShadow;
    transition: 0.8s;

    &.off-screen{
        transform: translateX(700px);
    }

    .info-panel-container{
        padding: 60px;
    }

    .info-icon{
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 99999px ){

    // Big Screens
    $bigGraphicColumnWidth: 60%;

    .auth-graphic-column{
        // background-color: rgba(255, 192, 203, 0.079);
        width: $bigGraphicColumnWidth;
    }

    .auth-form-column{
        width: calc(100% - $bigGraphicColumnWidth);
    }

    .auth-screen{
        font-size: 18px;
    }

    .authform-container{
        padding: 60px;
    }

    .auth-big-logo{
        display: block;
        height: 28px;
        left: 60px;
    }

    .auth-small-logo{
        display: none;
    }

    .authform-container{
        width: 100%;
        margin-top: 60px;

    }

}

@media only screen and (max-height: 700px){

    .auth-logo{
        display: none;
    }

    $mdGraphicColumnWidth: 50%;

    .auth-graphic-column{
        // background-color: rgba(255, 192, 203, 0.079);
        width: $mdGraphicColumnWidth;
    }

    .auth-form-column{
        width: calc(100% - $mdGraphicColumnWidth);
    }
}


@media only screen and (max-width: 1200px) {

    // Medium Screens

    

    .auth-screen{
        font-size: 18px;
    }

    .authform-container{
        padding: 0[x];
    }

    .auth-logo{
        margin: 0 auto;
        margin-bottom: 20px;
        height: 22px;
        top: 60px;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .auth-screen{
        text-align: center;
    }

    .auth-graphic-column{
        display: none;
    }

    .auth-form-column{
        width: 100%;
        text-align: center;
        
        // max-width: 500px;
    }

    .authform-container{
        max-width: 400px;
        margin: 0 auto;
        padding: 0px;
        width: 100%;
        margin-top: 60px;

    }

    

}

@media only screen and (max-width: 600px){
    //Small screens

    .auth-screen{
        font-size: 14px;
    }

    .authform-container{
        width: 80%;
        margin-top: 0px;
    }
}

.draggable-handle-auth{
    -webkit-app-region: drag;
    height: 100px;
    width: 100%;
    position: absolute;
    top: 0px;
}


