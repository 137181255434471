
@import '../../.../../../../styles/colors';
@import '../../.../../../../styles/shadows';
@import '../../../../ui/sizes';

.FileExplorer{
    width: 400px;
    background-color: white;

    .back-button{
        height: 10px;
        margin-right: 5px;
        padding: 5px;
        border-radius: 99px;

        &:hover{
            background-color: $lightHoverPurple;
        }
    }

    .navigator{

        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: $darkerGrey;

    }

    .files-list{
        height: 300px;
        margin-bottom: 20px;
        overflow-y: scroll;

    }
    
    .create-folder{
        display: flex;
        align-items: center;
    }
    
    .new-folder-name{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 300px;
    }

    .tick-icon{
        width: 14px;
        margin: 0px 10px;
        &:hover{
            filter:$filter_lightModeGreen
        }
    }
    
    .close-icon{
        height: 10px;
        margin: 0px 10px;
        &:hover{
            filter:$filter_lightModeRed
        }
    }

    .loading-create{
        height: 20px;
    }
    
    .folder-name-input{
        width: 100%;
        margin-right: 10px;
    }
    
    .folders{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .move-file{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        // color: red($color: red);
        transition: 0.3s;
        cursor: default;
        &.disable{
            cursor: not-allowed;
            color: lightgray;
            &:hover{
                background-color: none;
                .move-right-arrow{
                    visibility: initial;
                }
            }
        }
        &:hover{
            background-color: $lightHoverPurple;

            .move-right-arrow{
                visibility: visible;
            }
        }
    }
    
    .move-file-name{
        display: flex;
        flex-direction: row;
        width: 320px;
        height: 24px;
    }
    
    .file-icon{
        height: 24px;
        width: 24px;
        padding-right: 15px;
        padding-left: 10px;
    }
    
    .file-name{
        font-size: 1em;
        // color: $darkerGrey
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    
    
    .move-right-arrow{
        padding-right: 20px;
        width: 10px;
        height: 10px;
        cursor: pointer;
        // visibility: hidden;
    }
    
    .selected{
        filter:$filter_white
    }
    
    .readings{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .writings{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .buttons{
        padding-top: 3px;
        align-items: center;
        display: flex;
        justify-content: space-between;


        .createNewFolder{
            color: $darkPurple;
            font-weight: bold;
            font-size: 0.9em;
            transition: 0.3s;
            cursor: pointer;

            &.disabled{
                color: $darkerGrey;
                cursor: default;
            }

           
        }

        
    }
}

