@import '../../styles/colors';

.section{
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.titlePart{
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
    align-items: center;
}

.header{
    font-weight: bolder;
    font-size: 1.0em;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
}

.sectionIcon{
    height: 20px;
    width: 20px;
}

.downArrow,.rightArrow{
    height: 12px;
    width: 12px;
}

.downArrow:hover,.rightArrow:hover{
    cursor: pointer;
}

.downArrow{
    transform: rotate(90deg);
    transition: 0.3s;
}

.rightArrow{
    transform: rotate(0deg);
    transition: 0.3s;
}

.sectionContent{
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    padding-top: 10px;
}