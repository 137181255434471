
@import '../../../../../styles/colors';

.Header.mobile{
    display: flex;
    padding: 0px 10px;
    box-shadow:  0px 0px 2px 1px $lightGrey;

    .Header-item{
        align-items: center;
        display: flex;

    }

    .PageTitle-container{
        width: 70%;

    }

    .TopbarButtons-container{
        width: 25%;
    }

    .ProfileButton-container{
        width: 10%;
    }

}