
@import './_fonts.scss';
@import '../styles/colors';

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
    
    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        display: none;
        transition: 0.3s;
    }

    &::-webkit-scrollbar-track {
        background: $clipboardGrey;

        // border-top-right-radius: 10px;
        // border-left: 1px solid grey;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $mediumPurple;
        // margin-right: 5px;
        // border-left: 1px solid grey;
    }   
    &:hover{
        &::-webkit-scrollbar {
            transition: 0.3s;
            display: block;
        }
      } 
}

.show-scroll{
    &::-webkit-scrollbar {
        display: block;
    }
}

input{
    font-family: 'Poppins';
    border: none;
    background: none;

    &:focus{
        outline: none;
    }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select{
    font-family: 'Poppins';
    outline: none;
    &:focus{
        border:none;
    }
}

button{
    font-family: 'Poppins'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

