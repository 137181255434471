/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.dropbtn-icon {
  height: 15px;
  width: 15px;
  float: right;
  padding-top: 7px;
  padding-left: 5px;
}

.dropbtn-icon :hover.dark {
  background-color: #4e4e58;
}
.dropbtn-icon :hover.light {
  background-color: #BAB6F5;
}

/* container of all the dropdown options (Hidden by Default) */
.editor-tool {
  background: #f2f2f7;
  filter: var(--app-theme);
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.editor-tool.light {
  background-color: #f2f2f7;
  color: #34353f;
}
.editor-tool.light:hover {
  background-color: #BAB6F5;
}
.editor-tool.dark {
  background-color: #34353f;
  color: #f2f2f7;
}
.editor-tool.dark:hover {
  background-color: #4e4e58;
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.dropdown h1.editor-tool, .dropdown h2.editor-tool, .dropdown h3.editor-tool, .dropdown h4.editor-tool, .dropdown h5.editor-tool {
  margin: 0;
}/*# sourceMappingURL=HeadingToolbar.css.map */