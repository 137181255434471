.AnnotationBox {
  margin: 10px 5px;
  background-color: #e0e7ff;
  font-size: 0.9em;
}
.AnnotationBox .reading {
  font-size: 0.8em;
  background-color: #c7cafc;
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
}
.AnnotationBox .text {
  padding: 10px;
  display: flex;
  align-items: center;
}
.AnnotationBox .text .annotation-icon {
  margin: 0px 10px;
  background-color: #c7d2fe;
  height: 16px;
  width: 16px;
  padding: 5px;
  border-radius: 3px;
}
.AnnotationBox .text .textContent {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.AnnotationBox .text .textContent.grey-out {
  color: #4b4b4b;
}
.AnnotationBox .text .textContent i {
  padding: 0px 3px;
  color: #665aef;
}
.AnnotationBox .text .bolden {
  color: #4338ca;
  font-weight: bold;
}/*# sourceMappingURL=AnnotationBoxItem.css.map */