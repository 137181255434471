.AnnotationsTopbar-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}
.AnnotationsTopbar-container.n {
  justify-content: space-between;
}
.AnnotationsTopbar-container.s {
  justify-content: center;
}
.AnnotationsTopbar-container.s .TopbarTitle {
  display: none;
}

.Annotations-searchbox-container {
  display: flex;
  align-items: center;
}
.Annotations-searchbox-container .filter {
  height: 0.9em;
  width: 0.9em;
  padding: 5px;
}
.Annotations-searchbox-container .filter-container {
  display: flex;
  border-radius: 999px;
}
.Annotations-searchbox-container .filter-container:hover {
  scale: 0.9;
}

.Annotations-searchbox-div {
  border: 1px solid #c7cafc;
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 2px;
}
.Annotations-searchbox-div .search-icon {
  height: 14px;
}
.Annotations-searchbox-div .Annotations-input {
  font-size: 0.9em;
  padding-left: 5px;
  width: 95%;
  color: white;
}
.Annotations-searchbox-div .Annotations-input::-moz-placeholder {
  color: #c7cafc;
}
.Annotations-searchbox-div .Annotations-input::placeholder {
  color: #c7cafc;
}

.FilterSelector-container {
  position: absolute;
  top: 80px;
  right: 0px;
  background-color: white;
}

.AnnotationsWorkspace {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.AnnotationsWorkspace .AnnotationsList-container {
  height: 100%;
  overflow: auto;
}/*# sourceMappingURL=AnnotationsWorkspace.css.map */