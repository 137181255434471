/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.selected-filters {
  margin-bottom: 0px;
  text-align: left;
  padding: 10px;
  padding-bottom: 0px;
}

.filterBox {
  padding: 5px;
  font-size: 0.9em;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #c7cafc;
}

.filterBox-color {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.appliedFilter-item {
  display: flex;
  padding: 0px;
  align-items: center;
}

.annotation-icon-container {
  display: flex;
  align-items: center;
}

.annoType-filter-icon {
  height: 1em;
  width: 1em;
  border-radius: 3px;
  margin-right: 5px;
}/*# sourceMappingURL=AnnotationsFilterPreview.css.map */