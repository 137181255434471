
@import '../../../../styles/colors';

.AnnotationsTopbar-container{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    

    &.n{
        justify-content: space-between;
    }
    &.s{
        justify-content: center;

        .TopbarTitle{
            display: none;
        }
    }

}

.Annotations-searchbox-container{
    display: flex;
    align-items: center;

    .filter{
        height: 0.9em;
        width: 0.9em;
        padding: 5px;
    }

    .filter-container{
        display: flex;
        border-radius: 999px;
        &:hover{
            scale: 0.9
        }
    }

}

.Annotations-searchbox-div{
    border: 1px solid $mediumPurple;
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 2px;
    

    .search-icon{
        height: 14px;
    }

    .Annotations-input{
        font-size: 0.9em;
        padding-left: 5px;
        width: 95%;
        color: white;

        &::placeholder{
            color: $mediumPurple;


        }
    }

}

.FilterSelector-container{
    position: absolute;
    top: 80px;
    right: 0px;
    background-color: white;


}

.AnnotationsWorkspace{
    
    display: flex;
    flex-direction: column;
    height: calc(100%);
    overflow: hidden;

    .AnnotationsFilterPreview-container{

    }

    .AnnotationsList-container{
        height: calc(100%);
        overflow: auto;
    }
}