@import '../../../styles/shadows';

.OverlayMobile{
    background-color: rgba(0, 0, 0, 0.329);
    z-index: 100;
    width: 100vw;
    height: 100%;
    position: fixed;


    display: flex;
    justify-content: center;
    align-items:flex-end;

    

    .Overlay-container{
        box-shadow: $boxShadow;
        background-color: white;
        height: fit-content;
        // margin-top: 10%;
        position: relative;
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        animation-name: overlay;
        animation-duration: 0.3s;
    }

    .Overlay-heading{
        border-bottom: 2px solid $lightPurple;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2em;
        padding: 15px;


        &.overlay-heading-no-border{
            border-bottom: 0px;
        }

        .Overlay-heading-left-part{
            display: flex;
            align-items: center;
            width: 100%;
        }
        .Overlay-heading-icon{
            height: 24px;
            margin-right: 10px;
        }

        .Overlay-heading-title{
            width: calc(95% - 24px)
        }
    }

    .Overlay-contents{
        padding: 0px 10px;
        padding-bottom: 10px;
    }

    .Overlay-close-icon{
        height: 14px;
        padding: 5px;
        border-radius: 999px;
        float: right;
        cursor: pointer;

        &:hover{
            background-color: $lightPurple;
        }

    }
}

@keyframes overlay {
    0%   {top: 200vh;}
    100%  { top:0px;}
}

