.NotebookSelector h3 {
  font-size: 1em;
  margin: 5px 0px;
}
.NotebookSelector .subtitle {
  font-size: 0.9em;
  margin: 0px;
}
.NotebookSelector .NotebooksList-container {
  padding: 10px 0px;
  padding-top: 5px;
  margin-top: 20px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}/*# sourceMappingURL=NotebookSelector.css.map */