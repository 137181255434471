/*Simple css to style it like a toggle switch*/
/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.slider-menu-container {
  display: inline-flex;
  align-items: center;
}
.slider-menu-container em {
  margin-left: 10px;
  font-size: 1rem;
}

.option-switch {
  display: inline-block;
  position: relative;
}

.slider-bar {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s, visibility 0s, background-color 0s;
  border-radius: 5px;
}

.slider-bar:before {
  content: "";
  height: 34px;
  width: 34px;
  position: absolute;
  transition: all 0.4s, visibility 0s, background-color 0s;
  transform: translateX(var(--bevelPos));
  border-radius: 5px;
}

.slider-bar.dark:before {
  background-color: #444758;
}

.slider-bar.light:before {
  background-color: #665aef;
}

.slider-bar.dark.sliderstyle-menu {
  background-color: #212231;
}
.slider-bar.dark.sliderstyle-pagenav {
  background-color: #34353f;
}
.slider-bar.light.sliderstyle-menu {
  background-color: #e0e7ff;
}
.slider-bar.light.sliderstyle-pagenav {
  background-color: #f2f2f7;
}

.slider-bar.bevel {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.slider-img {
  position: relative;
  height: 24px;
  width: 24px;
  margin-right: 6px;
  margin-left: 6px;
  transition: 0.5s;
}
.slider-img.active-img {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}

.icons-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
}/*# sourceMappingURL=SliderTabs.css.map */