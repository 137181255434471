@import '../../../styles/colors';

$homeIconWidth: 44px;
$profileButtonWidth: 30px;

.TabsBar{
    text-align: left;
    display: flex;
    flex-direction: row;
    margin: 0px;
    height: 30px;
    align-items: center;
    width: calc(100% - $homeIconWidth - $profileButtonWidth);

    .tabs-container{
        height: 30px;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        // width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

    }

    .newTabIcon{
        // background-color: $darkPurple;
        // filter: $filter_darkPurple;
        margin-left: 10px;
        padding-right: 10px;
        height: 18px;
        cursor: pointer;
    }
}