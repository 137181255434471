/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.uploads-container {
  position: fixed;
  bottom: 40px;
  right: 50px;
  padding: 10px;
  border-radius: 2px;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  margin-bottom: 10px;
  text-align: left;
  z-index: 9999;
  background-color: #e0e7ff;
}
.uploads-container.dark {
  background-color: #313941;
}
.uploads-container.light {
  background-color: #e6e5ee;
}
.uploads-container .closePopup-icon {
  height: 12px;
  width: 12px;
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 4px;
  border-radius: 50%;
}
.uploads-container .closePopup-icon:hover {
  background-color: #c7cafc;
}

.upload-header {
  margin-left: 5px;
  font-weight: bold;
  margin-bottom: 15px;
}

.progress-fileName {
  font-size: 14px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 3px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-row {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.progress-bar-container {
  width: 100px;
  margin-left: 10px;
  height: 12px;
  background-color: white;
  margin-right: 10px;
  display: inline-block;
}
.progress-bar-container.app {
  width: 100%;
  margin-left: 0px;
}
.progress-bar-container .progress-bar-fill {
  height: 100%;
  color: transparent;
  background-color: #7ac74f;
  font-size: 12px;
}

.percentage {
  margin-left: 5px;
  font-size: 0.9em;
}

.upload-done-icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  filter: invert(44%) sepia(70%) saturate(481%) hue-rotate(68deg) brightness(93%) contrast(85%);
}/*# sourceMappingURL=FileUploadProgress.css.map */