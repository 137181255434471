.ProfilePreview {
  display: flex;
  align-items: middle;
}
.ProfilePreview .ProfilePreview-dp {
  background-color: #665aef;
  margin: 10px 5px;
  color: white;
  font-weight: bold;
  padding: 6px;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 20px;
  width: 20px;
  height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}
.ProfilePreview .ProfilePreview-account {
  display: flex;
  align-items: middle;
  vertical-align: middle;
  justify-content: center;
  text-align: left;
}
.ProfilePreview .ProfilePreview-account .account-details {
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}
.ProfilePreview .ProfilePreview-account .name {
  font-size: 1em;
}
.ProfilePreview .ProfilePreview-account .email {
  font-size: 0.85em;
  color: #727272;
}/*# sourceMappingURL=ProfilePreview.css.map */