@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';


.PageTitle-folder{
    color: $darkPurple;

}

.PageTitle-text{
    display: inline;
}


.PageTitle-location{
    color: $darkGrey;
}

.PageTitle.laptop{
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    vertical-align: middle;
    align-items: center;

    .PageTitle-location{
        font-size: 12px;
    }

    .PageTitle-folder{
        font-size: 24px;
        font-weight: bold;
        margin-top: 6px;
        margin-bottom: 6px;

        display: inline-flex;
        align-items: center;

        &:hover{
            .header-more-button{
                scale: 1.0;
            }
        }

        .header-more-button{
            height: 15px;
            margin-left: 10px;
            cursor: pointer;
            scale: 0;
            transition: 0.3s;
        }
    }

    .PageTitle-description{
        font-size: 14px;
        color: black;

        &.null{
            color: grey;
        }
    }

    
}

.PageTitle.mobile{
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .backButton{
        padding-right: 15px;
    }

    .PageTitle-location{
        font-size: 10px;
    }

    .PageTitle-folder{
        font-size: 18px;
        font-weight: bold;
        
    }

    .PageTitle-description{
        font-size: 14px;
        color: black;
        display: none;

        &.null{
            color: grey;
        }
    }
}





$height: 100px;

.PageTitle-icon{
    background-image: url('../../../../../../assets/icons/FolderMain.svg');
    background-size:     90px;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;              /* optional, center the image */

    height: 75px;
    width: 95px;
    margin-right: 32px;


    display: inline-flex;
    justify-content: space-between;
    // border: 1px solid black;
    align-items: center;
    vertical-align: center;
    user-select: none;
    cursor: pointer;

    .folder-more-button{
        padding-right: 2px;
        visibility: hidden;
        width: 90px;

        &.hovered{
            visibility: visible;
            display: flex;
            justify-content: end;
        }
    }

}