@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/shadows';

.searchBox{
    height: 70vh;
    width: 70vw;

    &.mobile{
        width: calc(100vw - 40px);
    }

    .searchInput-wrapper{
        &.mobile{
            display: none;
        }
    }
}