.SearchInput {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  width: calc(100% - 20px);
  padding: 0px 10px;
  justify-content: flex-start;
  height: 36px;
  vertical-align: middle;
  align-items: center;
  transition: 0.3s;
}
.SearchInput.active {
  border: 1px solid #665aef;
  box-shadow: 0 0 2px #665aef;
}
.SearchInput .Searchbar-icon {
  width: 16px;
}
.SearchInput .Searchbar-icon.mobile {
  display: none;
}
.SearchInput .Searchbar-input {
  width: calc(95% - 16px - 10px);
  padding-left: 10px;
  border: none;
}
.SearchInput .Searchbar-input:focus {
  border: none;
  outline: none;
}/*# sourceMappingURL=SearchInput.css.map */