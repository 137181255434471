.notebookBtn {
  position: absolute;
  right: 0;
  top: 50%;
  height: 35px;
  z-index: 5;
}

.Desk-write {
  background-color: #f2f2f7;
}/*# sourceMappingURL=Desk.mobile.css.map */