.ProfileButton {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 999px;
  background-color: #665aef;
  font-weight: bold;
  color: white;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProfileButton:hover {
  background-color: #665aef;
}
.ProfileButton.laptop {
  font-size: 12px;
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.ProfileButton.laptop.s {
  height: 24px;
  width: 24px;
}
.ProfileButton.mobile {
  height: 24px;
  width: 24px;
  line-height: 24px;
  font-size: 10px;
}

.profileDropdown {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  background-color: white;
  z-index: 200;
  position: absolute;
  right: 20px;
  top: 72px;
  padding: 10px 20px;
  background-color: white;
  border-radius: 2px;
}
.profileDropdown.Writer-dropdown {
  top: 40px;
  right: 0px;
}
.profileDropdown .ProfilePreview-container {
  padding: 5px 15px;
}
.profileDropdown hr {
  border: none;
  border-bottom: 2px solid #e0e7ff;
}/*# sourceMappingURL=ProfileButton.css.map */