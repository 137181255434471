.ReaderViewer {
  display: none;
  height: 100%;
}
.ReaderViewer.active {
  display: flex;
}

.EmptyReaderViewer {
  overflow: hidden;
}

.EmptyReaderViewer-FileExplorer {
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  padding: 10px;
  margin-bottom: 50px;
}

.EmptyViewer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.EmptyReaderViewer-text {
  width: 130px;
  font-size: 1.2em;
}

.EmptyReaderViewer-message {
  background-color: white;
  width: -moz-fit-content;
  width: fit-content;
}

.EmptyReaderViewer-message-container {
  height: 100%;
  margin-top: 30%;
  cursor: pointer;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  justify-content: center;
  text-align: center;
  align-items: center;
}/*# sourceMappingURL=ReaderViewer.css.map */