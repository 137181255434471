.Menu {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: left;
  color: black;
}
.Menu.s .label {
  font-size: 0.9em;
}
.Menu.s .Menu-icon {
  height: 24px;
  width: 24px;
}
.Menu.s .header {
  font-size: 0.9em;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Menu.s .dropdownElement {
  padding: 4px;
}
.Menu.n .label {
  font-size: 1em;
  margin-left: 20px;
}
.Menu.n .Menu-icon {
  height: 24px;
  width: 24px;
}
.Menu.n .header {
  font-size: 1.1em;
  margin-bottom: 15px;
}
.Menu.n .dropdownElement {
  padding: 5px 15px;
}
.Menu .triangleElement {
  color: white;
  font-size: 25px;
  display: flex;
  justify-content: end;
  padding-right: 10%;
  margin-bottom: -25px;
  z-index: 10;
}
.Menu .list {
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px;
  padding: 10px 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.Menu .dropdownElement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}
.Menu .dropdownElement:hover {
  background-color: #eff3ff;
}
.Menu .dropdownElement.toggle:hover {
  background-color: transparent;
}
.Menu .dropdownElement.warning {
  color: #db5461;
}
.Menu .dropdownElement.warning .Menu-icon {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.Menu .clickable {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Menu .label {
  font-weight: 400;
}
.Menu .toggleOptions {
  display: flex;
  flex-direction: row;
  padding: 2px;
  background-color: #D9D9D9;
}
.Menu .separator {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border: none;
  border-bottom: 2px solid #e7e7e7;
}
.Menu .header {
  color: #665aef;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
}
.Menu .li.l {
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  .Menu .triangleElement {
    display: none;
  }
  .Menu .dropdown {
    width: 100vw;
  }
  .Menu .list {
    box-shadow: 0px 0px;
  }
  .Menu .label {
    font-size: 18px;
    font-weight: 400;
  }
  .Menu .list {
    padding-top: 0;
  }
  .Menu .toggleOptions {
    margin-right: 50px;
  }
  .Menu .Menu-icon {
    height: 24px;
    width: 24px;
  }
}

.MenuElement-ToggleSwitch {
  margin-right: 7px;
}/*# sourceMappingURL=Menu.css.map */