.Folder {
  background-image: url("../../../../../assets/icons/FolderMain.svg");
  background-size: contain; /* <------ */
  background-repeat: no-repeat;
  background-position: center center; /* optional, center the image */
  height: 100px;
  width: 140px;
  margin-right: 18px;
  margin-bottom: 18px;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
.Folder.mobile {
  width: 25vw;
  height: 80px;
}
.Folder .Folder-title {
  padding: 0px 12px;
  margin-top: 20px;
  height: 45px;
  font-size: 14px;
  font-family: "Poppins-Medium";
}
.Folder .Folder-title.mobile {
  font-size: 0.8em;
  padding: 0px 7px;
}
.Folder .folder-more-button {
  padding-right: 2px;
  scale: 0;
  position: absolute;
  right: 0px;
  transition: 0.3s;
}
.Folder .folder-more-button.hovered {
  scale: 1;
}
.Folder.onDropActive {
  border: 1px #665aef solid;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}/*# sourceMappingURL=Folder.css.map */