.Icon-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: -moz-max-content;
  min-width: max-content;
}

.Icon {
  -webkit-user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.Icon.hovered:hover {
  filter: invert(81%) sepia(8%) saturate(2398%) hue-rotate(198deg) brightness(101%) contrast(98%);
}
.Icon.active {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
}

.HoverTip {
  margin-top: 70px;
  min-width: -moz-max-content;
  min-width: max-content;
  position: absolute;
  background-color: #e0e7ff;
  border-radius: 3px;
  color: black;
  padding: 4px;
  animation-name: popup;
  animation-duration: 0.1s;
  font-size: 0.85em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.195);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.HoverTip .hovertip-text {
  margin-left: 1px;
}
.HoverTip .hovertip-shortcut {
  background-color: #c7cafc;
  border-radius: 3px;
  color: black;
  padding: 3px 6px 3px 5px;
  font-size: 0.85em;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 2px 1px 2px 6px;
}

@keyframes popup {
  0% {
    transform: scale(0.1, 0.1);
    padding: 5x;
  }
  100% {
    padding: 5x;
    transform: scale(1, 1);
  }
}/*# sourceMappingURL=Icon.css.map */