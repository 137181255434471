
@import '../../../styles/colors';

.SharpButton{

    width: fit-content;
    padding: 5px 25px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    display: inline;
    border: none;

    &.primary{
        background-color: $darkPurple;
        color: white;
    }

    &.secondary{
        background: $lightPurple;
        color: black;
    }

}