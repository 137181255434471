@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/shadows';

$searchbarIconWidth: 16px;

.SearchInput{
    border: 1px solid grey;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    width: calc(100% - 20px);
    padding: 0px 10px;
    justify-content: flex-start;
    height: 36px;
    vertical-align: middle;
    align-items: center;
    transition: 0.3s;

    &.active{
        border: 1px solid $purple;
        box-shadow: 0 0 2px $purple;
    }

    .Searchbar-icon{
        width: $searchbarIconWidth;

        &.mobile{
            display: none;
        }
    }

    .Searchbar-input{
        width: calc(95% - $searchbarIconWidth - 10px);
        padding-left: 10px;
        border: none;
        &:focus{
            border: none;
            outline: none;
        }
    }

}