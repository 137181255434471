
.Writer{
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;

    &.mobile{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
    }
}