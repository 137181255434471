.searchResultElement-container {
  border-bottom: 1px grey solid;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.searchResultElement-container.mobile {
  width: 100vw;
}
.searchResultElement-container:hover {
  background-color: #eff3ff;
}
.searchResultElement-container:hover .hover-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.searchResultElement-container:hover .hover-icons .trash-icon, .searchResultElement-container:hover .hover-icons .star-icon {
  display: flex;
  justify-content: center;
  padding: 5px 8px;
}
.searchResultElement-container:hover .hover-icons .trash-icon .trash-icon-img, .searchResultElement-container:hover .hover-icons .star-icon .trash-icon-img {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.searchResultElement-container:hover .fileLocation {
  width: calc(100% - 360px - 50px - 74px);
}
.searchResultElement-container:hover .fileLocation.mobile {
  display: none;
}
.searchResultElement-container .file-info {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  overflow: hidden;
}
.searchResultElement-container .file-info .fileIcon {
  display: flex;
  align-items: center;
}
.searchResultElement-container .file-info .fileHeading {
  margin-left: 50px;
}
.searchResultElement-container .file-info .fileHeading.mobile {
  margin-left: 10px;
}
.searchResultElement-container .file-info .fileHeading .fileDescription {
  font-size: 0.8em;
  display: flex;
  flex-wrap: wrap;
}
.searchResultElement-container .file-info .fileHeading .fileName {
  color: #727272;
  width: 360px;
}
.searchResultElement-container .file-info .fileHeading .fileName .bolden {
  font-weight: bold;
  color: #4f49ba;
}
.searchResultElement-container .file-info .fileHeading .fileName.mobile {
  width: 100%;
}
.searchResultElement-container .file-info .fileLocation {
  width: calc(100% - 360px - 50px);
  display: flex;
  flex-wrap: wrap;
  color: #727272;
  margin-left: 30px;
}
.searchResultElement-container .file-info .fileLocation.mobile {
  display: none;
}
.searchResultElement-container .hover-icons {
  display: none;
}/*# sourceMappingURL=SearchResultElement.css.map */