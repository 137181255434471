
.Home.mobile{
    // display: flex;

    .Header-row{
        height: 80px;
        width: 100%;

    }

    .PageContents-row{
        width: 100%;



    }

    .Navbar-row{
        position: absolute;
        height: 80px;
        width: 100%;
        bottom: 0;
    }
}