.Tab {
  transform: translateY(0px);
  display: inline-flex;
  font-size: 0.8em;
  text-align: left;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: green;
  color: black;
  height: 20px;
  position: relative;
  padding: 6px 20px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.195);
  background-color: #f2f2f7;
  color: #665aef;
}
.Tab .tab-title {
  vertical-align: center;
  justify-content: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Tab.active-tab {
  background-color: #665aef;
  color: white;
}
.Tab.active-tab .closeIcon {
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}
.Tab.inactive-tab {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border: 1px solid #c7cafc;
}
.Tab.inactive-tab .closeIcon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
}
.Tab .closeIcon {
  height: 6px;
  display: none;
  position: absolute;
  right: 8px;
  padding-top: 5px;
  z-index: 500;
}
.Tab .closeIcon.mobile {
  display: inline;
}
.Tab .closeIcon:hover {
  filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg) brightness(97%) contrast(76%);
}
.Tab:hover .closeIcon {
  display: inline;
}/*# sourceMappingURL=Tab.css.map */