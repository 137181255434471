
@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';

$headerColor: $mediumPurple ;
$boxColor: $lightPurple ;
$iconColor: $indigo200;

.AnnotationBox{
    margin: 10px 5px;
    background-color: $boxColor;
    // padding: 10px;
    // border-radius: 3px;
    font-size: 0.9em;
    // box-shadow: $boxShadow;

    .reading{
        font-size: 0.8em;
        background-color: $headerColor;
        display: flex;
        justify-content: space-between;
        padding: 3px 10px;
    }
    .text{
        padding: 10px;
        display: flex;
        align-items: center;

        .annotation-icon{
            margin: 0px 10px;
            background-color: $iconColor;
            height: 16px;
            width: 16px;
            padding: 5px;
            border-radius: 3px;
        }

        .textContent{
            -webkit-line-clamp: 3;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;

            &.grey-out{
                color: $darkerGrey;
            }
            i{
                padding: 0px 3px;
                color: $purple;
            }

        }

        .bolden{
            color:$indigo700;
            font-weight: bold;
        }
    }
}