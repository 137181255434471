.drawing-shape {
  cursor: crosshair;
  position: fixed;
  margin: 0px;
  padding: 0px;
  top: 0;
}

.selection-box {
  border: 1px dotted #000;
  background-color: rgba(37, 139, 223, 0.3294117647);
  z-index: 1000;
}

.rectangle-box {
  background-color: blue;
}/*# sourceMappingURL=Shapes.css.map */