@import '../../../styles/variables';

.doublerangeSlider-container{
    // border: 1px solid black;
    width: 100px;
}

.double_slider {
    position: relative;
    width: 100%;
}

// .double-slider-range[type='mumber']{
//   &.pagenum-slider-.double-slider-range{
//     color: black;
//   }
//   -webkit-appearance: nones;
  
// }
.pagenum-slider-input,.double-slider-range{
  text-align: center;
  margin: auto;
//   width: fit-content;
//   max-width: 30px;
    width: 30px;
//   border: 1px solid pink;

  &.left{
    float: left;
    color:black;

  }

  &.right{
    float: right;
    color:black;

  }


}
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
  }
  
  .slider__track {
    background-color: green;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range {
    background-color: pink;
    z-index: 2;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
  }
  
  .thumb--zindex-3 {
    z-index: 3;
  }
  
  .thumb--zindex-4 {
    z-index: 4;
  }

  .thumb--zindex-5 {
    z-index: 5;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-double_slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  .slider-value{
    background-color: $lightHover;
    width: 32%;
    // position: relative;
    display: block;
    margin: auto;
    height: 15px;
    width: max-content;
    padding: 10px;
    text-align: center;
    margin-top: -50px;
    border-radius: 10px;
}

// .slider-value::before{
//     content: "";
//     position: absolute;
//     border-top: 15px solid $lightHover;
//     border-left: 15px solid transparent;
//     border-right: 15px solid transparent;
//     margin: auto;
//     bottom: -7px;

// }


.slider-wrapper{
    width: 100%;
    margin: auto;
    position: relative;
    display: block;
    margin-bottom:20px;
    margin-top: 20px;
}

.dr-slider-container{
    position:relative;
    margin:auto;
    width: 100%;
    display: flex;
}

.slider-track{
    width: 100%;
    height:5px;
    position: absolute;
    margin: auto;
    top: 0px;
    border-radius: 4px;
    bottom: 0px;
    background-color: $textboxColor;
}


.double-slider-range[type='range']{
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background-color: transparent;
}

.double-slider-range[type='range']::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 10px;
}

// .double-slider-range[type='range']::-moz-range-track{
//     -moz-appearance: none;
//     height: 15px;
// }

// .double-slider-range[type='range']::-ms-track{
//     appearance: none;
//     height: 15px;
// }

.double-slider-range[type='range']::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    background-color: $desklampButtonBg;
    cursor: pointer;
    border-radius: 50%;
    pointer-events: auto;
    // margin-top: -4px;
}

.double-slider-range[type='range']:active::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    background-color: transparent;
    border: 3px solid $desklampButtonBg;
    cursor: pointer;
    border-radius: 50%;
    pointer-events: auto;
}

.pagenum-slider-input{
    &.dark{
        color: white
    }
}