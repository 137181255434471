.NavbarMenuItem.laptop {
  display: flex;
  padding: 7px 30px;
  margin: 5px 0px;
  border-left: 6px solid transparent;
  cursor: pointer;
}
.NavbarMenuItem.laptop.active {
  background-color: #e0e7ff;
  border-left: 6px solid #665aef;
  font-weight: bold;
}
.NavbarMenuItem.laptop .NavbarMenuItem-icon {
  height: 20px;
  margin: 0;
  margin-right: 15px;
}/*# sourceMappingURL=NavbarMenu.css.map */