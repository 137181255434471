.TopbarDropper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 5px;
  font-size: 0.9em;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  white-space: nowrap;
}
.TopbarDropper .Topbar-arrowIcon {
  transition: 0.4s;
  rotate: 90deg;
  height: 8px;
  margin-left: 5px;
  margin-right: 0px;
  padding: 0px;
}
.TopbarDropper:hover {
  background-color: #5d51ee;
}
.TopbarDropper:hover .Topbar-arrowIcon {
  transform: translateX(3px);
}
.TopbarDropper .topmenu-dropdown {
  position: fixed;
  padding: 5px 0px;
  border-radius: 5px;
  background-color: #eff3ff;
  margin-top: 30px;
  transform: translateX(-50%);
  color: black;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}/*# sourceMappingURL=TopbarDropper.css.map */