
@import '../../../styles/layout';
@import '../../../../../styles/colors';
@import '../../../../../styles/shadows';


.IndexBar{
    position: absolute;
    z-index: 30;
    height: calc(100% - $topbarHeight);
    background-color: $lightHoverPurple;
    box-shadow: $boxShadow;
    width: 330px;

    .heading{
        color: $darkGrey;
        font-weight: 600;
        font-size: 0.9em;
        padding-top: 18px;
        padding-left: 10px;
        padding-bottom: 0px;
    }

    &.visible{

    }

    &.hidden{

    }

    &.mobile{
        width: 100vw;
        height: calc(100vh - $topbarHeight - $tabsBarHeight);
        z-index: 6;
    }
}

.index-list{
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}