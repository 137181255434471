
@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';

// $lightColor: rgb(255, 230, 230);
$lightColor: $lightHoverPurple;

.Document{
    display: inline-block;
    margin-bottom: 15px;
    // border: 1px solid black;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
    max-width: 125px;
    max-height: 174px;

    &.home{
        margin-right: 15px;
    }

    &.mobile{
        max-width: 20vw;
        max-height: 40vw;
    }


    background-color: transparentize($color: $lightColor, $amount: 0.5) ;
    &:hover{
        background-color: $lightColor;
    }

    &.Notebook{
        background-color: transparentize($color: $lightHoverPurple, $amount: 0.5) ;
        &:hover{
            background-color: $lightHoverPurple;
        }
    }

    text-align: center;

    .Document-fileType{
        color: $darkerGrey;
        width: 100%;
        font-size: 10px;
        margin-left: 24px;
        font-weight: bold;

    }

    .Document-Thumbnail{
        height: 108px;
        width: auto;
        box-shadow: $boxShadow;
        margin: 6px 0px;
    }

    .Document-title{
        font-size: 12px;
        height: 40px;
        width: 100%;
        overflow: none;
        vertical-align: middle;
        align-items: center;
        // border: 1px solid red;
        vertical-align: center;
        justify-content: center;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.more-button{
    float: right;

    .more-icon{
        height: 16px;
        position: absolute;
        margin-left: -14px;
        margin-top: -3px;
    }
    scale: 0.0;
    position: relative;
    transition: 0.3s;
    z-index: 2;


    &.hovered{
        scale: 1.0;
    }
}


