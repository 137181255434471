
@import '../../../../../../styles/colors';

.NavbarFooter.laptop{
    position: absolute;
    bottom: 0px;
    width: 100%;

    .NavbarFooterButton{
        display: flex;
        padding: 20px 15px;
        font-size: 14px;
        border-bottom: 0px;
        border-right: 0px;
        align-items: center;
    
        .NavbarFooterButton-icon{
            margin-right: 10px;
            height: 14px;
        }
    }
    
}


