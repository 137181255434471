.SimpleThumbnail {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 2px 1px #e7e7e7;
  text-align: center;
  height: 120px;
  max-height: 120px;
  width: 92px;
  max-width: 92px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  transition: 0.3s;
}
.SimpleThumbnail.n {
  width: 100px;
  max-width: none;
  height: 140px;
  max-height: none;
  margin-right: 18px;
}
.SimpleThumbnail.mobile {
  width: 15vw;
  max-width: 15vw;
  height: 20vw;
  max-height: 20vw;
}
.SimpleThumbnail .contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
.SimpleThumbnail .format {
  font-size: 0.8em;
  color: #665aef;
  opacity: 0.7;
}
.SimpleThumbnail .format.mobile {
  font-size: 0.7em;
  height: 0;
}
.SimpleThumbnail .title {
  font-size: 0.9em;
  color: #4b4b4b;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SimpleThumbnail .title.mobile {
  font-size: 0.65em;
}
.SimpleThumbnail .author {
  font-size: 0.8em;
  color: #c7c7c7;
}
.SimpleThumbnail .author.mobile {
  font-size: 0.7em;
}
.SimpleThumbnail:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  scale: 1.05;
}
.SimpleThumbnail .thumbnail-icon {
  height: 1.5em;
  position: absolute;
  top: 20px;
}
.SimpleThumbnail .thumbnail-icon.mobile {
  height: 1.2em;
}/*# sourceMappingURL=SimpleThumbnail.css.map */