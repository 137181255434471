.WriterTabs-home-icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
  margin: 0px 10px;
}

.WriterTabs-home-icon {
  filter: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg) brightness(89%) contrast(102%);
  margin: 0px 10px;
}

.WriterTabsBar {
  display: flex;
  text-align: center;
  width: 100%;
  font-size: 0.9em;
  justify-content: center;
}
.WriterTabsBar .sliderTabs-container {
  display: flex;
  align-items: center;
}
.WriterTabsBar .writerTabsBar-ProfileButton {
  position: absolute;
  margin-left: auto;
  display: flex;
  align-items: center;
  right: 10px;
}
.WriterTabsBar.n {
  align-items: center;
}
.WriterTabsBar.n .sliderTabs-container {
  margin-left: 10px;
}
.WriterTabsBar.s {
  padding: 0px 10px;
}
.WriterTabsBar.s .Workspace-text {
  display: none;
}
.WriterTabsBar.xs {
  align-items: center;
}
.WriterTabsBar.xs .Workspace-text, .WriterTabsBar.xs .sliderTabs-container {
  display: none;
}
.WriterTabsBar.mobile .Workspace-text, .WriterTabsBar.mobile .sliderTabs-container {
  display: flex;
}/*# sourceMappingURL=WriterTabsBar.css.map */