.Home.mobile .Header-row {
  height: 80px;
  width: 100%;
}
.Home.mobile .PageContents-row {
  width: 100%;
}
.Home.mobile .Navbar-row {
  position: absolute;
  height: 80px;
  width: 100%;
  bottom: 0;
}/*# sourceMappingURL=Home.mobile.css.map */