
@import '../../../../../../styles/colors';

$borderLeft: 6px;

.NavbarMenuItem.laptop{
    display: flex;
    padding: 7px 30px;
    margin: 5px 0px;
    border-left: $borderLeft solid transparent;
    cursor: pointer;

    &.active{
        background-color: $lightPurple;
        border-left: $borderLeft solid $darkPurple;
        font-weight: bold;
    }

    .NavbarMenuItem-icon{
        height: 20px;
        margin: 0 ;
        margin-right: 15px;
        
    }
}



