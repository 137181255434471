
@import '../../../../../../styles/colors';

$searchbarIconWidth: 16px;
$searchbarShortcutWidth: 44px;

.Searchbar.laptop{
    border: 1px solid grey;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    width: 400px;
    padding: 0px 10px;
    justify-content: space-between;
    height: 32px;
    vertical-align: middle;
    align-items: center;
    transition: 0.3s;

    &.active{
        border: 1px solid $purple;
        box-shadow: 0 0 2px $purple;
        // .Searchbar-icon{
        //     filter: $filter_darkPurple
        // }

    }

    .Searchbar-icon{
        width: $searchbarIconWidth;
    }

    .Searchbar-input{
        width: calc(95% - $searchbarIconWidth - $searchbarShortcutWidth - 10px);
        border: none;
        &:focus{
            border: none;
            outline: none;
        }
    }

    .Searchbar-shortcut{
        width: $searchbarShortcutWidth;
        // height: 100%;
        display: inline;
        // border: 1px solid red;
        color: $darkGrey;
        height: fit-content;
        user-select: none;
        // vertical-align: middle;

    }

}