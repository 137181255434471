@import '../../../../styles/colors';

.notebookBtn{
    position: absolute;
    right: 0;
    top: 50%;
    height: 35px;
    z-index: 5;
}

.Desk-write{
    background-color: $clipboardGrey;
}
