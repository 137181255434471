@import '../../../../../../styles/colors';
@import '../../../../../../styles/shadows';


.Tooltip{
    position: absolute;
    background-color: $lightHoverPurple;
    margin-top: 20px;
    margin-left: -40px;
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    box-shadow: $boxShadow;

    .triangle {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $lightHoverPurple;

        position: absolute;
        top: -5px;
        left: 50%;
        margin-left: -5px;
      }
    
      .tooltip-section{
        text-align: left;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      
}