@import '../../../../styles/colors';
@import '../../../../styles/shadows';

$widthDo: 0;

.Tab{
    // display: inline;
    // width: 20px;
    transform: translateY(0px);
    display: inline-flex;
    font-size: 0.8em;
    text-align: left;
    cursor: default;
    user-select: none;
    background-color: green;
    color: black;
    height: 20px;
    // margin: 0px -3px;
    position: relative;
    padding: 6px 20px;


    .tab-title{
        vertical-align: center;
        justify-content: center;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    //border
    
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: $lighterBoxShadow;

    //color
    background-color: $clipboardGrey;
    color: $darkPurple;


    &.active-tab{
        background-color: $darkPurple;
        color: white;
        // margin-right: 3px;

        .closeIcon{
            filter: $filter_white;
        }

    }

    &.inactive-tab{

        box-shadow: $boxShadow;
        border: 1px solid $mediumPurple;

        .closeIcon{
            filter: $filter_darkPurple;
        }

    }
    
    .closeIcon{
        height: 6px;
        display: none;
        position: absolute;
        right: 8px;
        padding-top: 5px;
        z-index: 500;

        &.mobile{
            display: inline;
        }

        &:hover{
            filter: $filter_lightModeRed;
        }
    }

    &:hover{
        .closeIcon{
            display: inline;
        }
    }    
}