@import '../../../../../styles/colors';

.PageNavigator{
    color: white;
    display: flex;
    height: 100%;
    align-items: center;

    .bookpage{
        border-right: 1px solid $lightPurple;
        margin-right: 5px;
        padding-right: 5px;
    }

    .PageNavigator-inputs{
        margin-right: 5px
    }
    
}

.PageNavigator-input{
    background-color: $lightPurple;
    width: 40px;
    color: $darkPurple;
}

.PageNavigatorDropdown{
    width: 200px;
    padding: 10px;

    .PageNavigator-txt{
        width: 100%;
        // word-wrap: break-word;
        // line-break: auto;
    }
}