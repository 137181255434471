
.drawing-shape{
    cursor: crosshair;
    position:fixed;
    margin: 0px;
    padding:0px;
    top:0;

}

.selection-box{
    border:1px dotted #000;
    background-color: #258bdf54;
    z-index: 1000;
}

.rectangle-box{
    // border: 1px solid green;
    background-color: blue;
}