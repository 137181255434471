.Searchbar.laptop {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  width: 400px;
  padding: 0px 10px;
  justify-content: space-between;
  height: 32px;
  vertical-align: middle;
  align-items: center;
  transition: 0.3s;
}
.Searchbar.laptop.active {
  border: 1px solid #665aef;
  box-shadow: 0 0 2px #665aef;
}
.Searchbar.laptop .Searchbar-icon {
  width: 16px;
}
.Searchbar.laptop .Searchbar-input {
  width: calc(95% - 16px - 44px - 10px);
  border: none;
}
.Searchbar.laptop .Searchbar-input:focus {
  border: none;
  outline: none;
}
.Searchbar.laptop .Searchbar-shortcut {
  width: 44px;
  display: inline;
  color: #727272;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}/*# sourceMappingURL=Searchbar.css.map */