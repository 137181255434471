.NewButton.mobile {
  background-color: #665aef;
  width: 48px;
  height: 48px;
  border-radius: 99px;
  vertical-align: middle;
  align-items: center;
  display: flex;
  z-index: 1;
  position: fixed;
  bottom: 100px;
  right: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.NewButton.mobile .NewButton-icon {
  height: 18px;
  margin: 0 auto;
  filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg) brightness(105%) contrast(100%);
}/*# sourceMappingURL=NewButton.mobile.css.map */