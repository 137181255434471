.explore-folders .folder-component {
  display: flex;
  flex-direction: column;
}
.explore-folders .folder-component .folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 20px;
  padding-right: 0px;
}
.explore-folders .folder-component .folder .folder-name {
  cursor: pointer;
  white-space: nowrap;
  overflow-wrap: unset;
  width: 80%;
}
.explore-folders .folder-component .folder .explore-folder-icon {
  padding: 0px 5px;
  cursor: pointer;
}
.explore-folders .folder-component .folder .explored {
  transform: rotate(90deg);
  transition: 0.3s;
}
.explore-folders .folder-component .folder .not-explored {
  transform: rotate(0deg);
  transition: 0.3s;
}
.explore-folders .folder-component .folder .folder-icon-name {
  display: flex;
  cursor: pointer;
}
.explore-folders .folder-component .folder .folder-icon-name .folder-icon {
  padding: 0px 5px;
  display: flex;
  align-items: center;
}
.explore-folders .folder-component .folder .folder-icon-name .opened {
  font-weight: bold;
}
.explore-folders .folder-component .isDropActive {
  border: 1px #665aef solid;
  background-color: #e0e7ff;
}
.explore-folders .folder-component .explored-folder-list {
  padding-left: 20px;
}
.explore-folders .no-folders {
  padding-left: 24px;
  color: gray;
}/*# sourceMappingURL=FolderAccordion.css.map */