@import '../../styles/colors';

.LegacyDesklampPopup-container{
    width: 500px;
    padding: 10px;
    color: rgb(38, 38, 38);

    a{
        text-decoration: none;
        color: $darkPurple;
    }
}

.LegacyDesklamp-container{
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
}


.LegacyDesklamp{
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #ffc45220;
    width: fit-content;
    border:2px solid #ffc452;
    border-radius: 5px;
    padding: 10px;
    transition: 0.3s;


    font-size: 0.8em;
    color: $darkerGrey;

    &:hover{
        background-color: #ffc55256;
    }

    .warning-icon{
        height: 1.0em;
        margin: 0px 10px
    }
}