@import '../../../../../../styles/colors';


.Topbar.laptop{
    display: flex;
    vertical-align: middle;
    width: 100%;
    height:64px;
    justify-content: space-between;
    // border-bottom: 2px solid $lightGrey;

    .sidebuttons-container{
        display: flex;
    }

    .Topbar-item{
        // vertical-align: middle;
        align-items: center;
    }

    .SearchBar-container{
        width: 80%;
        display: flex;


    }
    

    .ProfileButton-container{
        display: flex;
    }

    .TopbarButtons-container{
        // border: 1px solid black;
        text-align: right;
        display:flex ;
    }

    

}
