


.Collaborator{
    display: flex;

    .Collaborator-ProfilePreview{
        width: 85%;

    }

    .Collaborator-dropdown{
        display: flex;
    }

    .select-permission{
        outline: none;
        background-color: none;
        border: none;
        display: inline;
        margin-right: 16px;
    }
}