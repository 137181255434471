
@import '../../../styles/colors';
@import '../../../styles/shadows';

.Icon-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: max-content;
}

.Icon{
    -webkit-user-drag: none;
    user-select: none;

    &.hovered{
        &:hover{
            filter: $filter_mediumPurple;
        }
    }

    &.active{
        filter: $filter_darkPurple;
    }
}

.HoverTip{
    margin-top: 70px;
    min-width: max-content;
    position: absolute;
    background-color: $lightPurple;
    border-radius: 3px;
    color: black;
    padding: 4px;
    animation-name: popup;
    animation-duration: 0.1s;
    font-size: 0.85em;
    box-shadow: $lighterBoxShadow;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    // &.bottom{
    //     margin-top: 60px;
    //     margin-left: 24px;
    // }

    .hovertip-text{
        margin-left: 1px;
    }

    .hovertip-shortcut{
        background-color: $mediumPurple;
        border-radius: 3px;
        color: black;
        padding: 3px 6px 3px 5px;
        font-size: 0.85em;
        user-select: none;
        margin: 2px 1px 2px 6px;
    }
}

@keyframes popup {
    0%   {transform: scale(0.1,0.1); padding: 5x;}
    100% {padding: 5x; transform: scale(1,1);}
}