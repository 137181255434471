@import '../../../../../styles/colors';

.ReaderTabs-home-icon{
    filter: $filter_darkPurple;
    margin: 0px 10px;
    margin-right: 18px;
}

.writerTabsBar-ProfileButton{
    position: absolute;
    margin-left: auto;
    right: 10px;
}