.ZoomBox {
  margin-left: 20px;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
}

.ZoomDropdown-input {
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  width: 90%;
  margin-top: 6px;
  transition: 0.3s;
}
.ZoomDropdown-input:focus {
  border: 1px solid #665aef;
}

.zoom-option {
  padding-left: 12px;
  padding-right: 12px;
  transition: 0.3s;
  border-radius: 3px;
  margin: 4px 5px;
}
.zoom-option:hover {
  background-color: #c7d2fe;
}
.zoom-option .zoom-option-label {
  float: left;
}
.zoom-option .zoom-option-shortcut {
  float: right;
}/*# sourceMappingURL=ZoomController.css.map */