@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  /* Works on Chrome, Edge, and Safari */
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
*::-webkit-scrollbar-track {
  background: #f2f2f7;
}
*::-webkit-scrollbar-thumb {
  background-color: #c7cafc;
}
*:hover::-webkit-scrollbar {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: block;
}

.show-scroll::-webkit-scrollbar {
  display: block;
}

input {
  font-family: "Poppins";
  border: none;
  background: none;
}
input:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  font-family: "Poppins";
  outline: none;
}
select:focus {
  border: none;
}

button {
  font-family: "Poppins";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}/*# sourceMappingURL=index.css.map */