.DocumentTitle.writing {
  width: 100%;
}

.DocumentTitle {
  margin: 0px 15px;
  text-align: left;
  width: calc(50% - 130px);
}
.DocumentTitle .DocumentTitle-input {
  font-size: 0.9em;
  vertical-align: center;
  justify-content: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  padding: 3px;
  border-radius: 2px;
  min-width: 100%;
  max-width: 100%;
}
.DocumentTitle .DocumentTitle-input:hover {
  border: 1px solid #c7cafc;
}
.DocumentTitle .DocumentTitle-location {
  font-size: 0.6em;
  vertical-align: center;
  justify-content: center;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}/*# sourceMappingURL=DocumentTitle.css.map */